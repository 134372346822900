import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  images:any =[];
  controls:boolean =true;
  config:any;
  scrWidth:any;
  imageIndex:any = 0;
  constructor(
    public dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    if(this.data.galleryImagesSlider != []){
      this.data.galleryImagesSlider.forEach(element => {
          this.images.push(element.product_big_image_url);
      });
      this.imageIndex = this.data.imageIndex
    }
  }

  ngOnInit() {
    this.scrWidth = window.innerWidth;
    if(this.scrWidth <= 500){
      this.controls = false;
    }

    this.config = {
      wheelZoom: this.controls,
      btnClass: 'default', // The CSS class(es) that will apply to the buttons
      zoomFactor: 0.1, // The amount that the scale will be increased by
      containerBackgroundColor: '#fff', // The color to use for the background. This can provided in hex, or rgb(a).
      allowFullscreen: false, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
      allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
      btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
        zoomIn: 'fa-custom-plus',
        zoomOut: 'fa-custom-minus',
        rotateClockwise: 'fa fa-repeat',
        rotateCounterClockwise: 'fa fa-undo',
        next: 'fa-custom-right',
        prev: 'fa-custom-left',
        fullscreen: 'fa-custom-full',
      },
      btnShow: {
        zoomIn: this.controls,
        zoomOut: this.controls,
        rotateClockwise: false,
        rotateCounterClockwise: false,
        next: true,
        prev: true,
      }
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
