import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recentlyViewOrder'
})
export class RecentlyViewOrderPipe implements PipeTransform {
  recently_data:any = JSON.parse(localStorage.getItem("mitashi_recently_viewed"));
  transform(value: any, args?: any): any {
    if(value != undefined && value != "null"){
      let newArray:any = [];
      this.recently_data.reverse();
      this.recently_data.map((parent_element:any)=>{
        value.map((element:any)=>{
          if(element['product_id'] ==  parent_element){
            newArray.push(element);
            return;
          }
        })
      })
      return newArray;
    }
  }

}
