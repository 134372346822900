import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ProductCompareService } from 'src/app/services/product-compare.service';
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'app-product-compare',
  templateUrl: './product-compare.component.html',
  styleUrls: ['./product-compare.component.css','./product-compare.component.scss']
})
export class ProductCompareComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  baseUrl = environment.baseUrl;
  compareProductDetails:any;
  compareEmptyFlag:boolean = false;
  productDeatils:any;
  productColors:any;
  keyFeatures:any;
  productTechnicalFeatures:any;
  userId:any = "";
  constructor(
    private commonService:CommonService,
    private productCompareService:ProductCompareService,
    private snackBar:MatSnackBar,
    private dialog:MatDialog
  ){ 
    let user_data:any = JSON.parse(localStorage.getItem('mitashiUser'));
    if(user_data != null) {
      this.userId = user_data[0]['user_id'];
    }
  }

  ngOnInit() {
    let compareData = JSON.parse(localStorage.getItem('compareData'));
    let productIds:any = [];
    if(compareData != null){
      compareData.forEach(element => {
        productIds.push(element.product_id);
      });
      this.productCompareService.getCompareProducts(productIds).subscribe((response)=>{
        if(response.status_code == "200"){
          this.compareProductDetails = response.body.compare_details;
          this.productDeatils = this.compareProductDetails.product_details;
          this.keyFeatures = this.compareProductDetails.key_features;
          this.productColors = this.compareProductDetails.product_colors;
          this.productTechnicalFeatures = this.compareProductDetails.product_technical_features;
          this.compareEmptyFlag = false;
        }else{
          this.compareEmptyFlag = true;
        }
      })
    }else{
      this.compareEmptyFlag = true; 
    }
  }
  removeProductFromCompare(arrayKey:Number,productId:Number){
    this.compareProductDetails.product_details.splice(arrayKey,1);
    this.compareProductDetails.key_features.splice(arrayKey,1);
    this.compareProductDetails.product_colors.splice(arrayKey,1);
    this.compareProductDetails.product_technical_features.splice(arrayKey,1);
    let compareData = JSON.parse(localStorage.getItem('compareData'));
    if(compareData != null) {
      let index = -1;
      let isProductPresentIndex = compareData.some(function(el){ 
        index++;
        if(el.product_id === productId)
        return index
      });
      if(isProductPresentIndex > -1){
        compareData.splice(isProductPresentIndex,1);
        localStorage.setItem("compareData",JSON.stringify(compareData));
        this.productCompareService.changeCompareCount();
        this.snackBar.open("product Removed from Compare..!", "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:['bg-black']
        });
        let getcompareData = JSON.parse(localStorage.getItem('compareData'));
        if(getcompareData == null){
          this.compareEmptyFlag = true; 
        }
      }
    }
  }

  addEditWishList(product_data:any){
    if(this.userId != ""){
      if(product_data.wishlisted){
        this.commonService.removeFromWishlist(product_data.wishlist_id).subscribe(response=>{
          if(response.status_code == '200'){
            product_data.wishlisted = !product_data.wishlisted;
            product_data.wishlist_id = 0;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }
        });
      }else{
        this.commonService.addToWishList(product_data.product_id).subscribe(response=>{
          if(response.status_code == '200'){
            product_data.wishlisted = !product_data.wishlisted;
            product_data.wishlist_id = response.body.wishlist_id;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }
        });
      }
    }else{
      this.snackBar.open("Please Login for Add this Product To Your Wishlist ", "Close", {
        duration: 3000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
      setTimeout(() => {
        this.dialog.open(LoginComponent, {disableClose: false ,
          data: {}
        });
      }, 3000);
    }
  }
}
