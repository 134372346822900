import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  linkValidFlag:boolean = false;
  linkError:string = "";
  resetPasswordForm:FormGroup;
  alertResetMsgError:string = "";
  alertResetMsgSuccess:string = "";
  resetPasswordFormSubmitted:boolean = false;
  productLoading:boolean = false;
  forgotPasswordId:Number;
  constructor(
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private authService:AuthService,
    private formBuilder:FormBuilder
  ) { 
    this.activatedRoute.params.subscribe((parmas)=>{
      if(parmas.key != ""){
        this.getForgotDetails(parmas.key);
      }
    })
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password:     ['', Validators.required],
      cnfPassword: ['', Validators.required]
    },{
      
    });
  }

  getForgotDetails(key:string){
    this.authService.getForgotDetails(key).subscribe(response=>{
      if(response.status_code == "200"){
        this.forgotPasswordId = response.body.forgot_details[0].forgot_password_id;
        this.linkValidFlag = true;
      }else{
        this.linkValidFlag = false;
        this.linkError = response.msg;
      }
    });
  }

  onSubmit(){
    this.resetPasswordFormSubmitted = true;
    this.alertResetMsgError = this.alertResetMsgSuccess = "";
    if(this.resetPasswordForm.invalid) {
      return;
    }
    this.productLoading = true;
    this.authService.resetPassword(this.resetPasswordForm.value.password,this.resetPasswordForm.value.cnfPassword,this.forgotPasswordId).subscribe((response:any)=>{
      if(response.status_code == "200"){
        this.alertResetMsgSuccess = response.msg;
        this.alertResetMsgError = "";
        setTimeout(() => {
            this.router.navigate(["/"]);
        }, 3000);
      }else{
        this.alertResetMsgError = response.msg;
        this.alertResetMsgSuccess = "";
      }
      this.productLoading = false;
    });
  }

}
