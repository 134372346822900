import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductsComponent } from '../components/products/products.component';
import { ProductDetailsComponent } from '../components/products/product-details/product-details.component';
import { HomeComponent } from '../components/home/home.component';
import { LoginComponent } from '../components/login/login.component';
import { AuthGuard } from '../auth/auth.guard';
import { MyAccountComponent } from '../components/my-account/my-account.component';
import { SearchedProductsComponent } from '../components/products/searched-products/searched-products.component';
import { CartComponent } from '../components/cart/cart.component';
import { CheckoutComponent } from '../components/checkout/checkout.component';
import { CheckoutGuard } from '../auth/checkout.guard';
import { AuthMyaccountGuard } from '../auth/auth-myaccount.guard';
import { NotfoundComponent } from '../components/common/notfound/notfound.component';
import { ResetPasswordComponent } from '../components/login/reset-password/reset-password.component';
import { ProductCompareComponent } from '../components/products/product-compare/product-compare.component';
import { AddProductReviewComponent } from '../components/products/product-review/add-product-review/add-product-review.component';
import { ListProductReviewComponent } from '../components/products/product-review/list-product-review/list-product-review.component';
import { AllRecentlyViewedComponent } from '../components/common/recently-viewed/all-recently-viewed/all-recently-viewed.component';
import { AboutusComponent } from '../components/aboutus/aboutus.component';
import { PrivacypolicyComponent } from '../components/privacypolicy/privacypolicy.component';
import { InfinitScrollComponent } from '../components/infinit-scroll/infinit-scroll.component';
import { InpressComponent } from '../components/inpress/inpress.component';
import { InpressDetailsComponent } from '../components/inpress/inpress-details/inpress-details.component';
import { CareerComponent } from '../components/career/career.component';
import { ImageViewerComponent } from '../components/common/image-viewer/image-viewer.component';
import { PdfViewerComponent } from '../components/common/pdf-viewer/pdf-viewer.component';

const routes: Routes = [
 {
    path:"",
    component:HomeComponent
 },
 {
    path:"products/:category_id",
    component:ProductsComponent
  },
  {
    path:"products/:category_id/:subcategory_id",
    component:ProductsComponent
  },
  {
    path:"products-details/:product_id",
    component:ProductDetailsComponent
  },
  {
    path:"add-review/:product_link",
    component:AddProductReviewComponent,
  },
  {
    path:"product-reviews/:product_link",
    component:ListProductReviewComponent,
  },
  {
    path:"all-recently-viewed",
    component:AllRecentlyViewedComponent
  },
  {
    path:"searched/:key",
    component:SearchedProductsComponent
  },
  {
    path:"my-account",
    component:MyAccountComponent,
    canActivate:[AuthMyaccountGuard]
  },
  {
    path:"cart",
    component:CartComponent
  },
  {
    path:"checkout",
    component:CheckoutComponent,
    canActivate:[CheckoutGuard]
  },
  {
    path:"reset-password/:key",
    component:ResetPasswordComponent
  },
  {
    path:"compare",
    component:ProductCompareComponent
  },
  {
    path:"aboutus",
    component:AboutusComponent
  },
  {
    path:"privacypolicy",
    component:PrivacypolicyComponent
  },
  {
    path:"inpress",
    component:InpressComponent
  },
  {
    path:"inpress/:news_link",
    component:InpressDetailsComponent
  },
  {
    path:"career",
    component:CareerComponent
  },
  {
    path:"scroll",
    component:InfinitScrollComponent
  },
  {
    path:"viewdoc/:docname",
    component:PdfViewerComponent
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: '**', redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
