import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpInterface } from '../models/http-interface';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  apiUrl = environment.apiUrl;
  apiUrlTemp = environment.apiUrlTemp;
  constructor(
    private http:HttpClient
  ) { 

  }

  getCheckoutDetails(){
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    const formData = new FormData();
    formData.append("cartSession",localStorage.getItem("cart_session"));
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getCheckoutDetails",formData);
  }

  applyCoupon(couponCode:string){
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    const formData = new FormData();
    formData.append("cartSession",localStorage.getItem("cart_session"));
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("discountCode",couponCode);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"applyDiscount",formData);
  }
}
