import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpInterface } from '../models/http-interface';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})

export class CommonService {
  apiUrl = environment.apiUrl;
  apiUrlTemp = environment.apiUrlTemp;
  constructor(
    private http:HttpClient,
    private cookies:CookieService
  ) { }
  
  getHomeSettings(category_link:any = ""){
    const formData = new FormData();
    formData.append('category_link',category_link);
    return this.http.post<HttpInterface>(this.apiUrl+"getHomeSettings",formData);
  }

  getCategories(){
    return this.http.get<HttpInterface>(this.apiUrlTemp+"getCategoriesAndSubcategories");
  }

  getMenuData(){
    return this.http.get<HttpInterface>(this.apiUrlTemp+"getMenuDetails");
  }

  getAccountDetails(user_id:any){
    const formData = new FormData();
    formData.append('userId',user_id);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getMyAccountDetails",formData);
  }

  saveUserProfileInfo(formValues:any,user_id:any){
    const formData = new FormData();
    formData.append('userId',user_id);
    formData.append('firstName',formValues.first_name);
    formData.append('middleName',formValues.middle_name);
    formData.append('lastName',formValues.last_name);
    formData.append('contactNo',formValues.contact_number);
    formData.append('changePwd',formValues.change_password_check);
    formData.append('currentPwd',formValues.current_password);
    formData.append('newPwd',formValues.new_password);
    formData.append('cnfPwd',formValues.confirm_password);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"saveUserProfileInfo",formData);
  }
  
  getAllProducts(category_link:any,subcategory_link:any,productSort:string,page_offset:any,itemsPerPage:any){
    const formData = new FormData();
    // let category_filter_array:any= JSON.parse(localStorage.getItem("categoryFilter"));
    
    // if(category_filter_array !== null){
    //   if(category_filter_array.length > 0){
    //     let count:number = 0;
    //     for(let val of category_filter_array){
    //       formData.append('productFilters['+count+']',val);
    //       count ++;
    //     }
    //   }
    // }
    formData.append('categoryFilter',localStorage.getItem("categoryFilter"));
    let price_filter_array:any= JSON.parse(localStorage.getItem("priceFilter"));
    if(localStorage.getItem("priceFilter") !== null){
      if(price_filter_array.length > 0){
        // const price_array = price_filter_array[0].split(',');
        // formData.append('pricerangefrom',price_array[0]);
        // formData.append('pricerangeto',price_array[1]);
        formData.append('pricerangefrom',price_filter_array[0]);
        formData.append('pricerangeto',price_filter_array[1]);
      }
    }
    formData.append('link',category_link);
    if(subcategory_link != ""){
      formData.append('subLink',subcategory_link);
    }

    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if(  $user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }

    formData.append('page',page_offset);
    formData.append('itemsperpage',itemsPerPage);
    formData.append('sortby',productSort);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getProducts",formData);
  }

  getFilters(category_link:any){
    const formData = new FormData();
    formData.append('link',category_link);
    // return this.http.post<HttpInterface>(this.apiUrlTemp+"getFilters",formData);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getCategoryFilters",formData);
  }

  getProductDetails(product_link:string){
    const formData = new FormData();
    formData.append('product_link',product_link);
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getProductDetails",formData);
  }

  getSearchedProduct(productKey:string,productSort:string,page_offset:string,itemPerPage:string){
    const formData = new FormData();
    formData.append("searchTerm",productKey);
    formData.append("sortby",productSort);
    formData.append("page",page_offset);
    formData.append("itemPerPage",itemPerPage);
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"searchProducts",formData);
  }


  addUpdateAddress(formValues:any,addressId:string = ""){
    const formValuesArray = Object.keys(formValues).map(key => ({key: key, value: formValues[key]}));
    const formData = new FormData();
    for (let inputValue of formValuesArray){
      formData.append(inputValue["key"],inputValue["value"]);
    }
    if(addressId != ""){
      formData.append('addressId',addressId);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"addEditAddress",formData);
  }

  getAddress(userId:string,addressId:string = ""){
    const formData = new FormData();
    formData.append("userId",userId);
    if(addressId != ""){
      formData.append("addressId",addressId);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getAddress",formData);
  }

  makeDefaultAddress(addressId:string,userId:any){
    const formData = new FormData();
    formData.append("addressId",addressId);
    formData.append("userId",userId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"setDefaultAddress",formData);
  }

  deleteAddress(addressId:string,userId:any){
    const formData = new FormData();
    formData.append("addressId",addressId);
    formData.append("userId",userId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"deleteAddress",formData);
  }

  addToWishList(productId:string){
    const formData = new FormData();
    formData.append("productId",productId);
    formData.append("quantity","1");
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"addToWishlist",formData);
  }

  updateWishlist(wishlistData:any){
    const formData = new FormData();
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("wishlistData",JSON.stringify(wishlistData));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"updateWishlist",formData);
  }

  removeFromWishlist(wishlistId:string){
    const formData = new FormData();
    formData.append("wishlistId",wishlistId);
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"removeFromWishlist",formData);
  }

  removeProductFromWishlist(wishlistId:any){
    const formData = new FormData();
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("wishlistId",wishlistId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"removeFromWishlist",formData);
  }

  removeAllProductFromWishlist(){
    const formData = new FormData();
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("cartSession",localStorage.getItem("cart_session"));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"addAllToCartFromWishlist",formData);
  }

  addReview(formValues:any){ 
    const formData = new FormData();
    const formValuesArray = Object.keys(formValues).map(key => ({key: key, value: formValues[key]}));
    for (let inputValue of formValuesArray){
      formData.append(inputValue["key"],inputValue["value"]);
    }
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
      formData.append("emailId",$user_data[0]['email_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"addReview",formData);
  }
  
  
  getAllReviews(productLink:any,page:any,itemsPerPage:any){
    const formData =  new FormData();
    formData.append("productLink",productLink);
    formData.append("page",page);
    formData.append("itemsPerPage",itemsPerPage);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getAllReview",formData);
  }

  getReviewAccess(productLink:any){
    const formData =  new FormData();
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    formData.append("productLink",productLink);
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getReviewAccess",formData);
  }

  newsletterSubscription(emailId:string){
    const formData = new FormData();
    formData.append("email",emailId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"newsletterSubscription",formData);
  }

  newsLetterUnSubscribe(emailId:string){
    const formData = new FormData();
    formData.append("emailId",emailId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"newsLetterUnSubscribe",formData);
  }

  getRecentlyViewedProducts(productIds:any){
    const formData = new FormData();
    formData.append("productIds",productIds);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getRecentlyViewedProducts",formData);
  }

  getNewsDetails(news_link:any = "",pageOffset:any = 0){
    const formData = new FormData();
    formData.append('newslink',news_link);
    formData.append('page',pageOffset);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getNews",formData);
  }

  getOpeningDetails(){
    return this.http.get<HttpInterface>(this.apiUrlTemp+"getOpeningDetails");
  }

  applyForCareer(careerDetails:any){
    const formData = new FormData();
    formData.append('firstname',careerDetails.controls.first_name.value);
    formData.append('middlename',careerDetails.controls.middle_name.value)
    formData.append('lastname',careerDetails.controls.last_name.value);
    formData.append('gender',careerDetails.controls.gender.value);
    formData.append('email',careerDetails.controls.email.value);
    formData.append('mobile',careerDetails.controls.mobile.value);
    formData.append('message',careerDetails.controls.message.value);
    formData.append('designation',careerDetails.controls.designation.value);
    formData.append('resume',careerDetails.controls.resume.value);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"careers",formData);  
  }
}
