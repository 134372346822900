import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inpress',
  templateUrl: './inpress.component.html',
  styleUrls: ['./inpress.component.css']
})
export class InpressComponent implements OnInit {
  @ViewChild("allNewsDiv") allNewsDiv:any;
  newsLink:string = "";
  newsList:any;
  isFullListDisplayed:boolean = false;
  pageOffset:any = 1;
  lodingImage:boolean = true;
  LoaderBottom:boolean = false;
  totalNews:any;
  itemsPerPage:any = 10;
  constructor(
    private commonService:CommonService,
    private route:Router,
    private activatedRoute:ActivatedRoute
  ) { 
    this.activatedRoute.params.subscribe((params)=>{
      this.newsLink = params['newsLink'];
    })
  }

  ngOnInit() {
    this.getNewsDetails();
  }

  ngAfterViewInit(){
    this.allNewsDiv.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }


  getNewsDetails(callFrom = ""){
    if(callFrom != "scroll"){
      this.lodingImage = true;
      this.pageOffset = 1;
    }
    this.commonService.getNewsDetails(this.newsLink,this.pageOffset).subscribe((response)=>{
      if(response.status_code == "200"){
        if(response.body != null){
          if(callFrom != "scroll"){
            this.newsList =   response.body.news_details;
          }else{
            let existing = this.newsList;
            let newValue = response.body.news_details;
            Array.prototype.push.apply(existing,newValue); 
            this.newsList =  existing;
          }
          this.totalNews = response.body.news_details_count;
          if(this.pageOffset == Math.ceil(this.totalNews/this.itemsPerPage)){
            this.isFullListDisplayed = true;
          }else{
            this.isFullListDisplayed = false;
          }
        }else{
          if(this.pageOffset == 1){
            this.newsList =  {};
            this.totalNews = 0;
          }
        }
      }
      this.lodingImage = false;
      this.LoaderBottom = false;
    });
  }

  onScroll() {
    if(this.isFullListDisplayed == false) {
      this.LoaderBottom = true;
      this.pageOffset++;
      this.getNewsDetails("scroll");
    }
  }

}
