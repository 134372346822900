import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatDialog } from '@angular/material';
import { CartService } from 'src/app/services/cart.service';
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'app-searched-products',
  templateUrl: './searched-products.component.html',
  styleUrls: ['./searched-products.component.css']
})
export class SearchedProductsComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  baseUrl = environment.baseUrl;
  productSort:string;
  productKey:string;
  allProducts:any;
  productLoading:any = true;
  totalProducts:any;
  pageOffset:any = 0;
  itemsPerPage:any = 15;
  noProductFound:any = false;
  userId:any = 0;
  constructor(
    private commonService : CommonService,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private snackBar:MatSnackBar,
    private cartService:CartService,
    private dialog:MatDialog
  ){
    let user_data:any = JSON.parse(localStorage.getItem('mitashiUser'));
    if(user_data != null) {
      this.userId = user_data[0]['user_id'];
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params =>{
      this.productKey = params.key.replace(/(^\s+|\s+$)/g,'');
      this.getSearchedProducts();
    });
  }

  getSearchedProducts(){
    this.productLoading = true;
    let productSearchSubscription = this.commonService.getSearchedProduct(this.productKey,this.productSort,this.pageOffset,this.itemsPerPage).subscribe(response=>{
      this.productLoading = false;
      this.productLoading = false;
      if(response.status_code == "200" && response.body != null){
        if(response.body.products.length > 0){
          this.allProducts = response.body.products;
          this.totalProducts = response.body.total_products;
          this.noProductFound = false;
        }else{
          this.allProducts = [];
          this.noProductFound = true;
        }
      }else{
        this.allProducts = [];
        this.noProductFound = true;
      }
      productSearchSubscription.unsubscribe();
    });
  }

  addEditWishList(product_data:any){
    if(this.userId != ""){
      if(product_data.wishlisted){
        this.commonService.removeFromWishlist(product_data.wishlist_id).subscribe(response=>{
          if(response.status_code == '200'){
            product_data.wishlisted = !product_data.wishlisted;
            product_data.wishlist_id = 0;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }
        });
      }else{
        this.commonService.addToWishList(product_data.product_id).subscribe(response=>{
          if(response.status_code == '200'){
            product_data.wishlisted = !product_data.wishlisted;
            product_data.wishlist_id = response.body.wishlist_id;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }
        });
      }
    }else{
      this.snackBar.open("Please Login for Add this Product To Your Wishlist ", "Close", {
        duration: 3000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
      setTimeout(() => {
        this.dialog.open(LoginComponent, {disableClose: false ,
          data: {}
        });
      }, 3000);
    }
  }

  // addTocart(product_id:any){
  //   let cart_subscription = this.cartService.addToCart(product_id).subscribe(response =>{
  //     if(response.status_code == "200"){
  //       if(response.body.action == "add"){
  //         if(localStorage.getItem("cartCount") != null){
  //           let existing_cart_count:any = localStorage.getItem("cartCount");
  //           existing_cart_count = Number(existing_cart_count)+1;
  //           localStorage.setItem("cartCount",existing_cart_count);
  //         }
  //         this.cartService.changeCartCount();
  //         this.snackBar.open(response.msg, "Close", {
  //           duration: 2000,
  //           verticalPosition: 'bottom'
  //         });
  //       }else{
  //         this.snackBar.open(response.msg, "Close", {
  //           duration: 2000,
  //           verticalPosition: 'bottom'
  //         });
  //       }
  //     }else{
  //       this.snackBar.open(response.msg, "Close", {
  //         duration: 2000,
  //         verticalPosition: 'bottom'
  //       });
  //     }
  //     cart_subscription.unsubscribe();
  //   });
  // }
}
