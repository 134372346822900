import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpInterface } from '../models/http-interface';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductCompareService {
  apiUrl = environment.apiUrl;
  apiUrlTemp = environment.apiUrlTemp;
  @Output() compareCount: EventEmitter<any> = new EventEmitter();
  @Output() compareFlag: EventEmitter<any> = new EventEmitter();
  @Output() compareProducts: EventEmitter<any> = new EventEmitter();
  constructor(
    private router:Router,
    private http:HttpClient,
    private activatedRoute:ActivatedRoute,
  ) { 
  
  }

  changeCompareCount() {
    let compareData = JSON.parse(localStorage.getItem('compareData'));
    if(compareData != null){
      this.compareProducts.emit(compareData);
    }else{
      this.compareProducts.emit([]);
    }
   
  }

  updateCompareValue() {
    return this.compareProducts;
  }

  changeCompareFlag(){
    if(this.router.url == "/compare"){
      this.compareFlag.emit(false);
    }else{
      this.compareFlag.emit(true);
    }
  }

  updateCompareFlag() {
    return this.compareFlag;
  }

  getCompareProducts(prodctIds:any){
    const formData = new FormData();
    formData.append("productIds",JSON.stringify(prodctIds));
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getCompareProducts",formData);
  }
}
