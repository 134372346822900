import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CartService } from 'src/app/services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-all-recently-viewed',
  templateUrl: './all-recently-viewed.component.html',
  styleUrls: ['./all-recently-viewed.component.css']
})
export class AllRecentlyViewedComponent implements OnInit {
  assetsUrl:string = environment.assetsUrl;
  recentlyViewedData:any;
  pageOffset:Number = 0; 
  totalProducts:Number = 0;
  itemsPerPage:Number = 15;
  paginate:Number;
  productLoading:boolean = true;
  constructor(
    private commonService:CommonService,
    private cartService:CartService,
    private router:Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      this.getRecentlyViewedProducts();
    });
  }

  getRecentlyViewedProducts(){
    this.productLoading = true;
    if(localStorage.getItem("mitashi_recently_viewed") != "" && localStorage.getItem("mitashi_recently_viewed") != null && localStorage.getItem("mitashi_recently_viewed") != "undefined"){
      const productIds:any = JSON.parse(localStorage.getItem("mitashi_recently_viewed"));
      this.totalProducts = productIds.length;
      productIds.reverse();
      if(this.pageOffset > 0){
        this.paginate = (Number(this.pageOffset ) - 1) * Number(this.itemsPerPage);
      } else{
        this.paginate = Number(this.pageOffset) * Number(this.itemsPerPage);
      }
      const records = Number(this.paginate) + Number(this.itemsPerPage);
      let productPagedValue =  productIds.slice(this.paginate,records).map(val=>{
        return val;
      })
      this.commonService.getRecentlyViewedProducts(JSON.stringify(productPagedValue)).subscribe((response)=>{
        if(response.status_code=="200"){
          this.recentlyViewedData = response.body.recently_viewed;
        }else{
          this.recentlyViewedData =[];
        }
        this.productLoading = false;
      })
    }else{
      this.productLoading = false;
    }
  }
}
