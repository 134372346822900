import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  @ViewChild("mainDiv") mainDiv:any;
  openingsDetails:any;
  careerForm:FormGroup;
  careerFormSubmitted:boolean = false; 
  fixedFilterFlag:boolean = true;
  fileToUpload: File = null;
  constructor(
    private commonService:CommonService,
    private formBuilder:FormBuilder ,
    public dialog: MatDialog,
    public router:Router,
    public snackBar:MatSnackBar
  ) { 
  }

  ngAfterViewInit(){
    this.mainDiv.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  ngOnInit() {
    this.careerForm = this.formBuilder.group({
      first_name:["",[Validators.required]],
      middle_name:["",[Validators.required]],
      last_name:["",[Validators.required]],
      gender:["",[Validators.required]],
      email:["",[Validators.required,Validators.email]],
      mobile:["",[Validators.required,Validators.pattern("^[0-9]*")]],
      message:["",[Validators.required]],
      designation:["",[Validators.required]],
      resume:["",[Validators.required]],
    })
    this.getOpeningsDetails();
  }
  onSubmit(){
      this.careerFormSubmitted = true; 
      if(this.careerForm.valid){
        this.commonService.applyForCareer(this.careerForm).subscribe((response)=>{
          if(response.status_code =="200"){
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }
        })
      }else{
        return;
      }
  }
  getOpeningsDetails(){
    this.commonService.getOpeningDetails().subscribe((response)=>{
      if(response.status_code == "200"){
        this.openingsDetails = response.body.openings; 
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth <= 992){
      this.fixedFilterFlag = false;
    }else{
      this.fixedFilterFlag = true;
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.careerForm.controls['resume'].setValue(this.fileToUpload);
  }

}
