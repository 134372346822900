import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
  
})
export class LoadingComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
