import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpInterface } from '../models/http-interface';
import { CookieService } from 'ngx-cookie-service';
import { CartService } from './cart.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.apiUrl;
  apiUrlTemp = environment.apiUrlTemp;
  responseMsg:string;
  loginResponse:any;
  constructor(
    private router: Router,
    private http:HttpClient,
    private cartService:CartService
  ) {
    
  }
  
  loginUser(email_id:string,password:string){
    const formData = new FormData();
    formData.append('emailId',email_id);
    formData.append('password',password);
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    formData.append('cartSession',localStorage.getItem("cart_session"));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"login",formData);
  }

  guestUserlogin(email_id:string){
    const formData = new FormData();
    formData.append('emailId',email_id);
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    formData.append('cartSession',localStorage.getItem("cart_session"));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"guestlogin",formData);
  }

  logout(){
    localStorage.removeItem('cart_session');
    this.cartService.changeCartCount();
    localStorage.removeItem('mitashiUser');
    if(this.router.url == "/my-account"){
      this.router.navigate(['/']);
    }else{
      this.router.navigate([this.router.url]);
    }
  }

  isUserLoggedIn(){
    if (localStorage.getItem('mitashiUser')) {
      return true;
    }
  }

  userRegister(formValues:any){ 
    const formValuesArray = Object.keys(formValues).map(key => ({key: key, value: formValues[key]}));
    const formData = new FormData();
    for (let inputValue of formValuesArray){
      formData.append(inputValue["key"],inputValue["value"]);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"registration",formData);
  }

  forgotPassword(emailId:string){
    const formData = new FormData();
    formData.append('emailId',emailId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"forgotPassword",formData);
  }

  getForgotDetails(key:string){
    const formData = new FormData();
    formData.append("forgotKey",key);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getForgotDetails",formData);
  }

  resetPassword(newPassword:string,confirmPassword:string,forgotPasswordId:any){
    const formData = new FormData();
    formData.append("newPassword",newPassword);
    formData.append("confirmPassword",confirmPassword);
    formData.append("forgotPasswordId",forgotPasswordId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"resetPassword",formData);
  }
}
