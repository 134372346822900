import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Event, ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';
import { HeaderComponent } from '../header/header.component';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  loading:any = true;
  constructor(
    private activatedRoute:ActivatedRoute,
    private router:Router,
    ) {
      
    }

  ngOnInit() {
  }

}
