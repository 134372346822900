import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { HttpInterface } from 'src/app/models/http-interface';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  home_banner:any;
  home_settings:any;
  home_review:any;
  section1_images:any;
  press_image_url:string;
  recycle_image_url:string;
  preLoading:any = true;
  starproduct_image1_url_960X800:string;
  starproduct_image2_url_480X400:string;
  starproduct_image3_url_480X400:string;
  starproduct_image4_url_960X400:string;
  startProducts:any = {
    starproduct_image1_link:"",
    starproduct_image2_link:"",
    starproduct_image3_link:"",
    starproduct_image4_lin:""
  } 
  paramsSubscription:any;
  bannerNavArrowLeft:string = this.assetsUrl+"images/left-arrow.png";
  bannerNavArrowRight:string = this.assetsUrl+"images/right-arrow.png";
  bannerOptions:any = {
    items: 1,
    dots: false,
    nav:true,
    navigation: false,
    autoplay:true,
    loop:true,
    arrow:true,
    autoplaySpeed:1000,
    smartSpeed:1000,
    navText: ["<img src="+this.bannerNavArrowLeft+">", "<img src="+this.bannerNavArrowRight+">"]
  };

  section1Option:any = {
    items: 2,
    margin:20,
    dots: false,
    nav:true,
    navigation: false,
    autoplay:true,
    loop:false,
    arrow:false,
    autoplaySpeed:2000,
    smartSpeed:450,
    navText: ["<img src="+this.bannerNavArrowLeft+">", "<img src="+this.bannerNavArrowRight+">"]
  } 

  constructor(
    private commonService:CommonService,
    private router:ActivatedRoute,
    private auth:AuthService,
    private snackBar:MatSnackBar,
  ) { 
    this.paramsSubscription = this.router.params.subscribe(params=>{
      this.getHomeSettings();
    });
    this.paramsSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  getHomeSettings(){
   this.commonService.getHomeSettings().subscribe((response:any)=>{
      if(response.status_code == "200"){
        this.home_banner = response.body.banner_images;
        this.section1_images = response.body.section1_images;
        this.home_review = response.body.home_reviews;
        this.home_settings = response.body.homesettings;
        for (let home_settings_imges of this.home_settings) {            
          this.starproduct_image1_url_960X800 = home_settings_imges.starproduct_image1_url;
          this.starproduct_image2_url_480X400 = home_settings_imges.starproduct_image2_url;
          this.starproduct_image3_url_480X400 = home_settings_imges.starproduct_image3_url;
          this.starproduct_image4_url_960X400 = home_settings_imges.starproduct_image4_url;
          this.startProducts.starproduct_image1_link = home_settings_imges.starproduct_image1_link;
          this.startProducts.starproduct_image2_link = home_settings_imges.starproduct_image2_link;
          this.startProducts.starproduct_image3_link = home_settings_imges.starproduct_image3_link;
          this.startProducts.starproduct_image4_link = home_settings_imges.starproduct_image4_link;
          this.press_image_url = home_settings_imges.press_image_url;
          this.recycle_image_url = home_settings_imges.recycle_image_url;
        }
        this.preLoading = false;
      }else{
        this.snackBar.open(response.msg, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:['bg-black']
        });
      }
    });
  }
}
