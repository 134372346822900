// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // baseUrl:"http://attoinfotech.website/demo/mitashi/mitashi/",
  // baseUrl:"http://localhost:4200/",
  // assetsUrl:"/assets/",
  // assetsUrl:"http://attoinfotech.website/demo/mitashi/mitashi/assets/",
  // apiUrl:"http://attoinfotech.website/demo/mitashiservices/mitashiapi/",
  // apiUrlTemp:"http://attoinfotech.website/demo/mitashiservices/mitashiapi_temp/"
  baseUrl:"https://www.mitashi.com/",
  assetsUrl:"https://www.mitashi.com/assets/",
  apiUrl:"https://www.mitashi.com/mitashiservices/mitashiapi/",
  apiUrlTemp:"https://www.mitashi.com/mitashiservices/mitashiapi_temp/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
