import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { MatSnackBar } from '@angular/material';
import { ProductCompareService } from 'src/app/services/product-compare.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { stringify } from 'querystring';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  baseUrl = environment.baseUrl;
  subscriptionForm:FormGroup;
  subscriptionFormSubmitted:boolean =false;
  compareFlag:boolean = true;
  compareCount:any = 0;
  compareListFlag:boolean = false;
  compareProducts:any;
  constructor(
    private formBuilder: FormBuilder,
    private commonService:CommonService,
    private snackBar:MatSnackBar,
    private productCompareService:ProductCompareService,
    private router:Router
  ) { 
    
  }

  ngOnInit() {
    this.subscriptionForm = this.formBuilder.group({
      subscribeEmail: ['', [Validators.required, Validators.email]]
    });
    this.productCompareService.updateCompareValue()
    .subscribe(item =>{
      this.compareProducts  = item;
      this.compareCount = item.length;
    });
    this.productCompareService.updateCompareFlag()
    .subscribe(item =>{
      this.compareFlag = item; 
    });
    this.productCompareService.changeCompareCount();
  }

  removeFromCompare(arrayIndex:Number){
    let compareData = JSON.parse(localStorage.getItem('compareData'));
    compareData.splice(arrayIndex,1);
    localStorage.setItem("compareData",JSON.stringify(compareData));
    this.productCompareService.changeCompareCount();
  }

  showHideCompareList(action:string){
    if(action == "show"){
      this.compareListFlag = true;
    }else{
      this.compareListFlag = false;
    }
  }
  gotoCompare(){
    if(this.compareCount > 1){
      this.router.navigate(['/compare']);
    }else{
      this.snackBar.open("Please select atleast two products for Compare", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
    }
  }
  onSubmit(){
    this.subscriptionFormSubmitted = true;
    if(this.subscriptionForm.invalid) {
        return;
    }else{
      this.commonService.newsletterSubscription(this.subscriptionForm.value.subscribeEmail).subscribe((response)=>{
        if(response.status_code == "200"){
          this.subscriptionForm.reset();
          this.subscriptionFormSubmitted = false;
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      });
    }
  }
}
