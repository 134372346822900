import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../login/login.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddAddressDialogComponent } from '../my-account/add-address-dialog/add-address-dialog.component';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ModelDialogComponent } from '../common/model-dialog/model-dialog.component';
import { ConfirmDialogComponent } from '../common/confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  baseUrl = environment.baseUrl;
  assetsUrl = environment.assetsUrl;
  orderDetails:any ;
  userId:any = "";
  userLoggedinFlag:boolean = false;
  addressDetails:any;
  productTotalAmount:any;
  discountCouponForm:FormGroup;
  couponError:string = "";
  couponSuccess:string = "";
  finalTotal:number = 0;
  discountAmount:Number = 0 ;
  constructor(
    private formBuilder: FormBuilder,
    private commonService:CommonService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private cartService:CartService,
    private dialog:MatDialog,
    private snackBar:MatSnackBar,
    private checkoutService:CheckoutService
  ) {
    this.activatedRoute.params.subscribe(param=>{
      let current_user = JSON.parse(localStorage.getItem('mitashiUser'));
      if(current_user != null) {
        this.userId = current_user[0].user_id;
        this.userLoggedinFlag = true;
      }
      this.getCheckoutDetails();
    });
  }
  ngOnInit() {
    this.discountCouponForm = this.formBuilder.group({
      discountCoupon: ['']
    });
    let cartCount:any = localStorage.getItem("cartCount");
    if(cartCount <= 0){
      this.router.navigate(["/cart"]);
    }
  }

  removeCoupon(){
    this.discountAmount = 0;
    this.discountCouponForm.reset();
    this.couponSuccess = "";
    this.couponError = "Coupon Removed..";
    setTimeout(() => {
      this.couponSuccess = this.couponError = "" ;
    }, 5000);
  } 
  applyCoupon(){
    if(this.discountCouponForm.value.discountCoupon != ""){
      this.checkoutService.applyCoupon(this.discountCouponForm.value.discountCoupon).subscribe((response)=>{
        if(response.status_code == "200"){
          this.discountAmount = Number(response.body.discount_amount);
          this.couponSuccess = response.msg;
          this.couponError = "";
        }else{
          this.discountAmount = 0
          this.couponError = response.msg;
          this.couponSuccess = "";
        }
      })
    }else{
      this.couponError = "Please Enter Coupon Code";
      setTimeout(() => {
        this.couponSuccess = this.couponError = "";
      }, 5000);
    }
  }
  getTotalOfSubtotals(orderProduct:any){
    let Total:any = 0;
    orderProduct.forEach(item => {
      Total = Number(Total) + (Number(item.base_price) * Number(item.quantity));
    });
    return Total;
  }
  getCheckoutDetails(){
    this.checkoutService.getCheckoutDetails().subscribe((response)=>{
      if(response.status_code == "200"){
        this.addressDetails = response.body.address_details;
        this.orderDetails = response.body.orders_details;
        this.productTotalAmount = this.getTotalOfSubtotals(this.orderDetails);
      }else{
        this.addressDetails = [];
      }
    })
  }

  
  loginOrRegister(){
    this.dialog.open(LoginComponent, {disableClose: false ,
      data: {}
    });
  }
 
  addAddressDialog(addressId:string = "") {
    this.dialog.open(AddAddressDialogComponent, {disableClose: true ,
      data: {
        addressId:addressId
      }
    });
  }

  makeDeliveryAddress(addressId:string,isDefault:string,addressTitle:string){
    let radioFlag:boolean = false;
    if(isDefault == "No"){
      const dialogRef = this.dialog.open(ConfirmDialogComponent,{disableClose: true ,
        width: '250px',
        data: {
          confirmMsg:"Are you sure to Make "+addressTitle+" for Delivery..?"
        }
      });
      dialogRef.afterClosed().subscribe(response=>{
        if(response){
          radioFlag = response;
          this.commonService.makeDefaultAddress(addressId,this.userId).subscribe(response=>{
            if(response.status_code == "200"){
              this.snackBar.open("Address Selected as Delivery Address", "Close", {
                duration: 3000,
                verticalPosition: 'top',
                panelClass:['bg-black']
              });
              this.router.navigate([this.router.url]);
            }else{
              this.snackBar.open(response.msg, "Close", {
                duration: 3000,
                verticalPosition: 'top',
                panelClass:['bg-black']
              });
            }
          })
        }else{
          return;
        }
      })
      if(!radioFlag){
        return false;
      }
    }else{
      this.dialog.open(ModelDialogComponent, {
        width: '250px',
        data: {
          alertMsg:"The Address Already set For Shipping Address"
        }
      });
    }
  }
  
  payNow(){

  }
}
