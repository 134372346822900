import { Component, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

interface confirmData{
  confirmMsg:string;
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  confirmMsg:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: confirmData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ){
    this.confirmMsg = data.confirmMsg;
  }

  onYesClick(){
    this.dialogRef.close(true);
  }

  noClick(){
    this.dialogRef.close(false);
  }

}