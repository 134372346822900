import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  assetsUrl:any = environment.assetsUrl;
  paramsSubscription:any;
  doc_name:any = "";
  file_name:string = "";
  constructor(
    private activatedRouter:ActivatedRoute,
  ) {
    this.paramsSubscription = this.activatedRouter.params.subscribe(params=>{
      this.file_name = params['docname'];
      this.doc_name = this.assetsUrl+"documents/"+params['docname'];
    });
  }

  ngOnInit() {

  }

}
