import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CartService } from 'src/app/services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recently-viewed',
  templateUrl: './recently-viewed.component.html',
  styleUrls: ['./recently-viewed.component.css']
})
export class RecentlyViewedComponent implements OnInit {
  baseUrl:any = environment.baseUrl;
  assetsUrl:any = environment.assetsUrl;
  recentlyViewedData:any;
  bannerNavArrowLeft:string = this.assetsUrl+"images/left-arrow.png";
  bannerNavArrowRight:string = this.assetsUrl+"images/right-arrow.png";
  recentlyViewed_responsive:any = {
    0 : {
      items:1
    },
    480 : {
      items:3
    },
    1000 : {
      items:4
    }
  } 
  recentlyViewedOptions:any = {
    responsive:this.recentlyViewed_responsive,
    dots: false,
    nav:true,
    navigation: false,
    autoplay:false,
    loop:false,
    arrow:true,
    autoplaySpeed:2000,
    navText: ["<img src="+this.bannerNavArrowLeft+">", "<img src="+this.bannerNavArrowRight+">"]
  }
  allrecentViewFlag:boolean = false;
  constructor(
    private commonService:CommonService,
    private cartService:CartService,
    private router:Router,
    private activatedRoute:ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params)=>{
      this.getRecentlyViewedProducts();
    })
  }

  ngOnInit() {
  }

  getRecentlyViewedProducts(){
    if(localStorage.getItem("mitashi_recently_viewed") != "" && localStorage.getItem("mitashi_recently_viewed") != null && localStorage.getItem("mitashi_recently_viewed") != "undefined"){
      const productIds:any = JSON.parse(localStorage.getItem("mitashi_recently_viewed"));
      productIds.reverse();
      const size = 10;
      if(productIds.length > size){
        this.allrecentViewFlag = true;
      }else{
        this.allrecentViewFlag = false;
      }
      let productPagedValue =  productIds.slice(0,size).map(val=>{
        return val;
      })
      this.commonService.getRecentlyViewedProducts(JSON.stringify(productPagedValue)).subscribe((response)=>{
        if(response.status_code=="200"){
          this.recentlyViewedData = response.body.recently_viewed;
        }else{
          this.recentlyViewedData =[];
        }
      })
    }
  }
}
