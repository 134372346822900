import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  @ViewChild("aboutUsTop") allProductDiv: ElementRef;
  constructor() { }

  ngOnInit() {
    this.allProductDiv.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

}
