import { Component, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
@Component({
  selector: 'app-infinit-scroll',
  templateUrl: './infinit-scroll.component.html',
  styleUrls: ['./infinit-scroll.component.css']
})
export class InfinitScrollComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  someKeyboardConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [2000, 50000],
    keyboard: true,  // same as [keyboard]="true"
    step: 1,
    pageSteps: 10,  // number of page steps, defaults to 10
    range: {
      min: 2000,
      max: 50000
    },
    pips: {
      mode: 'count',
      // density: 2,
      values: 2, 
      stepped: true
    }
  };
  constructor() {
    
  }

  ngOnInit(): void {
    this.galleryOptions = [
      { "previewZoom": true,previewArrows:false},
      { "breakpoint": 500, "width": "300px", "height": "300px", "thumbnailsColumns": 3 },
      { "breakpoint": 300, "width": "100%", "height": "200px", "thumbnailsColumns": 2 },
    ];

    this.galleryImages = [
      {
          small: 'https://www.fujifilmusa.com/products/digital_cameras/x/fujifilm_x20/sample_images/img/index/ff_x20_008.JPG',
          medium: 'https://www.fujifilmusa.com/products/digital_cameras/x/fujifilm_x20/sample_images/img/index/ff_x20_008.JPG',
          big: 'https://www.fujifilmusa.com/products/digital_cameras/x/fujifilm_x20/sample_images/img/index/ff_x20_008.JPG'
      },
      {
          small: 'https://sample-videos.com/img/Sample-jpg-image-10mb.jpg',
          medium: 'https://sample-videos.com/img/Sample-jpg-image-10mb.jpg',
          big: 'https://sample-videos.com/img/Sample-jpg-image-10mb.jpg'
      }, {
          small: 'https://www.fujifilmusa.com/products/digital_cameras/x/fujifilm_x20/sample_images/img/index/ff_x20_008.JPG',
          medium: 'https://www.fujifilmusa.com/products/digital_cameras/x/fujifilm_x20/sample_images/img/index/ff_x20_008.JPG',
          big: 'https://www.fujifilmusa.com/products/digital_cameras/x/fujifilm_x20/sample_images/img/index/ff_x20_008.JPG'
      },
      {
          small: 'https://sample-videos.com/img/Sample-jpg-image-10mb.jpg',
          medium: 'https://sample-videos.com/img/Sample-jpg-image-10mb.jpg',
          big: 'https://sample-videos.com/img/Sample-jpg-image-10mb.jpg'
      }
    ];
    
    
  }
  ngAfterViewInit(){
      
  }
   

}
