import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[myMatchHeight]'
})
export class MatchEqualHeightDirective {
    
   // class name to match height
   @Input()
   myMatchHeight: any;

   constructor(private el: ElementRef) {
    
   }
   
   ngAfterViewChecked() {
       // call our matchHeight function here later
       this.matchHeight(this.el.nativeElement, this.myMatchHeight);
   }

   @HostListener('window:load') 
   onload() {
       // call our matchHeight function here later
       this.matchHeight(this.el.nativeElement, this.myMatchHeight);
   }
   @HostListener('window:resize') 
   onResize() {
        // console.log("called");
       // call our matchHeight function here later
       this.matchHeight(this.el.nativeElement, this.myMatchHeight);
   }

   matchHeight(parent: HTMLElement, className: string) {
       // match height logic here
       if (!parent) return;
       const children = parent.getElementsByClassName(className);
        // console.log(children);
       if (!children) return;

       // reset all children height
       Array.from(children).forEach((x: HTMLElement) => {
           x.style.height = 'initial';
       })

       // gather all height
       const itemHeights = Array.from(children).map(x => x.getBoundingClientRect().height);
    //    console.log(itemHeights);
       // find max height
       const maxHeight = itemHeights.reduce((prev, curr) => {
           return curr > prev ? curr : prev;
       }, 0);
       // apply max height
       Array.from(children).forEach((x: HTMLElement) => x.style.height = `${maxHeight}px`);
   }
}
