import { Component, OnInit, AfterViewInit, Host, AfterViewChecked, ViewChild, ElementRef, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpInterface } from 'src/app/models/http-interface';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryOrder, NgxGalleryLayout, NgxGalleryImageSize } from 'ngx-gallery';
import { CartService } from 'src/app/services/cart.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { OwlCarousel } from 'ngx-owl-carousel';
import { LoginComponent } from '../../login/login.component';
import { ProductCompareService } from 'src/app/services/product-compare.service';
import { StarRatingComponent } from 'ng-starrating';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageViewerComponent } from '../../common/image-viewer/image-viewer.component';

declare var jssor_1_slider_init: any;
declare var callCourosal:any;
declare var imageZoom:any;
declare var lightGallery:any;

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements AfterViewInit {
 
  @ViewChild("BuyNowSection") BuyNowSection: ElementRef;
  @ViewChild("SpecificationSection") SpecificationSection: ElementRef;
  @ViewChild("FeatureSection") FeatureSection: ElementRef;
  @ViewChild('owlElement') owlElement: OwlCarousel;
  @ViewChild("colorSection") colorSection: ElementRef;
  @ViewChild("ReviewsSection") ReviewsSection: ElementRef;
  assetsUrl = environment.assetsUrl;
  baseUrl = environment.baseUrl;
  reviewForm:FormGroup;
  reviewFormSubmit:boolean = false;
  productId:any;
  categoryId:any;
  productDetails:any;
  productImages:any;
  productCode:any;
  productName:any;
  productModel:any;
  productSku:any;
  prodcutWarranty:any;
  productLink:any;
  productWeight:any;
  productFinalPrice:any;
  shortDescription:any;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryImagesSlider:any;
  productFeatures:any;
  breadcrumLinks:any;
  productTechniacalFeatures:any;
  productColors:any;
  productSelectedColor:any;
  productDescription:any;
  productKeyFeatures:any;
  wishlistedFlag:boolean = false;
  wishlistedId:any;
  userId:any = 0;
  test:any;
  expandToggle:boolean = false;
  expandButtonText:string = "Show All";
  thumbScroolTop:number = 0;
  productLoading:any = false;
  bannerNavArrowLeft:string = this.assetsUrl+"images/left-arrow.png";
  bannerNavArrowRight:string = this.assetsUrl+"images/right-arrow.png";
  scrHeight:any;
  scrWidth:any;
  compareProductDetails:any;
  galleryImageOptions:any;
  snackbarClasses:any = ['bg-black','snack-bar-margin'];
  FeaturesOptions:any = {
    items:1,
    dots: true,
    nav:true,
    navigation: false,
    autoplay:true,
    loop:true,
    arrow:true,
    mouseDrag: false,
    navText: ["<img src="+this.bannerNavArrowLeft+">", "<img src="+this.bannerNavArrowRight+">"]
  } 
  pinchzoomOption:any ={
    type: "double-tap"
  } 
  productReviewDetails:any;
  overAllRating:Number = 0;
  totalReviewCount:Number = 0;
  rating1:Number = 0;
  rating2:Number = 0;
  rating3:Number = 0;
  rating4:Number = 0;
  rating5:Number = 0;
  ratingDatas:any;
  navDotToggle:boolean = true;
  bigScreen:boolean = true;
  constructor(
    private route:Router,
    private commonServices:CommonService,
    private activatedRoute:ActivatedRoute,
    private cartService:CartService,
    private snackBar:MatSnackBar,
    private dialog:MatDialog,
    private productCompareService:ProductCompareService,
    private formBuilder:FormBuilder
  ) { 
    let user_data:any = JSON.parse(localStorage.getItem('mitashiUser'));
    if(user_data != null) {
      this.userId = user_data[0]['user_id'];
    }
    this.activatedRoute.params.subscribe((params)=>{
      this.getProductDetails(params['product_id']);
      this.productLoading = true;
    });
  }
  ngAfterViewInit(){
    this.getScreenSize();
    
  }
  

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    console.log(this.scrWidth);
    if(this.scrWidth <= 600){
      this.navDotToggle = true;
      this.bigScreen = false;
    }else{
      this.navDotToggle = false;
      this.bigScreen = true;
    }
    this.galleryImageOptions = {
      items:1,
      dots: this.navDotToggle,
      nav:false,
      navigation: false,
      autoplay:false,
      loop:true,
      arrow:true,
      mouseDrag  : false,
      navText: ["<img src="+this.bannerNavArrowLeft+">", "<img src="+this.bannerNavArrowRight+">"]
    }
  }
  ngOnInit() {
    this.BuyNowSection.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

    //review form
    this.reviewForm = this.formBuilder.group({
      review_title:   ['', Validators.required],
      review_comments: ['', Validators.required],
      review_rating:   ['', Validators.required]
    })
  }

  expandToggleActions(){
    if(this.expandToggle){
      this.expandToggle = false;
      this.expandButtonText = "Show All";
    }else{
      this.expandToggle = true;
      this.expandButtonText = "Hide All";
    }
  }

  getProductDetails(product_id:string){
    this.commonServices.getProductDetails(product_id).subscribe((response:HttpInterface)=>{
      if(response.status_code == "200"){
        //product details
        this.productDetails = response.body.product_details;
        this.productId = this.productDetails[0].product_id;
        this.categoryId = this.productDetails[0].category_id;
        this.productLink = this.productDetails[0].link;
        this.productName = this.productDetails[0].product_name;
        this.productModel = this.productDetails[0].product_model;
        this.productSku = this.productDetails[0].product_sku;
        this.prodcutWarranty = this.productDetails[0].product_warranty;
        this.productWeight = this.productDetails[0].product_weight;
        this.shortDescription = this.productDetails[0].short_description;
        this.productFinalPrice = this.productDetails[0].product_price;
        this.productDescription =this.productDetails[0].product_description_val;
        this.wishlistedFlag = this.productDetails[0].wishlisted;
        this.wishlistedId = this.productDetails[0].wishlist_id;
        //addto recent view list
        this.addToRecentlyViewed(this.productId);
        //product colors
        this.productColors = response.body.product_colors;
        if(this.productColors != [] && this.productColors.length > 0){
          this.productSelectedColor = this.productColors[0].color_id;
        }
        //gallery image
        this.galleryImagesSlider = response.body.product_images;
        // if(this.bigScreen == false){
          this.galleryOptions = [
            { "previewZoom": true,previewArrows:false},
            { "breakpoint": 500, "width": "300px", "height": "300px", "thumbnailsColumns": 3 },
            { "breakpoint": 300, "width": "100%", "height": "200px", "thumbnailsColumns": 2 },
          ];
          let temp_array = [];
          this.galleryImagesSlider.forEach(element => {
            const obj = {
              small:element.product_thumbnail_image_url ,
              medium: element.product_big_image_url,
              big: element.product_big_image_url
            }
            temp_array.push(obj);
          });
          this.galleryImages = temp_array;
        // }

        //product featurers
        this.productFeatures = <HttpInterface>response.body.product_features;
        //get breadcrum
        this.breadcrumLinks = <HttpInterface>response.body.breadcrum_links;
        //get technical features
        this.productTechniacalFeatures = <HttpInterface>response.body.product_technical_features
        //keyfeatures
        this.productKeyFeatures = <HttpInterface>response.body.key_features;
        this.productLoading = false;

        //compare details
        this.compareProductDetails = {
          product_id: this.productId,
          product_name: this.productName,
          link: this.productLink,
          category_id: this.categoryId,
          product_image_path: response.body.product_images[0].product_thumbnail_image_url,
        };

        //productReviewDetails
        this.productReviewDetails = response.body.product_reviews;
        if(this.productReviewDetails){
          if(response.body.reviews_rating_datas.length>0){
            this.ratingDatas = response.body.reviews_rating_datas;
            this.overAllRating = this.ratingDatas[0].overAll;
            this.totalReviewCount = this.ratingDatas[0].total_user;
            this.rating1 = ( this.ratingDatas[0].rating_wise_percent['1'] != "undefined")?this.ratingDatas[0].rating_wise_percent['1']:0;
            this.rating2 = (this.ratingDatas[0].rating_wise_percent['2'] != "undefined")?this.ratingDatas[0].rating_wise_percent['2']:0;
            this.rating3 = (this.ratingDatas[0].rating_wise_percent['3'] != "undefined")?this.ratingDatas[0].rating_wise_percent['3']:0;
            this.rating4 = (this.ratingDatas[0].rating_wise_percent['4'] != "undefined")?this.ratingDatas[0].rating_wise_percent['4']:0;
            this.rating5 = (this.ratingDatas[0].rating_wise_percent['5'] != "undefined")?this.ratingDatas[0].rating_wise_percent['5']:0;
          }
        }
      }else{
        this.route.navigate(['404']);
      }
    });
  }

  scrollToSection(el:ElementRef) {
    el.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  addTocart(product_id:any){
    /* if(this.productSelectedColor != "" &&  this.productSelectedColor != undefined){ */
      let cart_subscription = this.cartService.addToCart(product_id,this.productSelectedColor="").subscribe(response =>{
        if(response.status_code == "200"){
          if(response.body.action == "add"){
            if(localStorage.getItem("cartCount") != null){
              let existing_cart_count:any = localStorage.getItem("cartCount");
              existing_cart_count = Number(existing_cart_count)+1;
              localStorage.setItem("cartCount",existing_cart_count);
            }
            this.cartService.changeCartCount();
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:this.snackbarClasses
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:this.snackbarClasses
            });
          }
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:this.snackbarClasses
          });
        }
        cart_subscription.unsubscribe();
      });
    /* }else{
      this.snackBar.open("Please Select Product Color", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:this.snackbarClasses
      });
      this.scrollToSection(this.colorSection);
    } */
    
  }

  thumbScroll(scrollType:string){
    // this.thumbScroolTop = 0;
    if(scrollType == "top"){
      let temp = this.thumbScroolTop + 100;
      if(temp > 100 ){
        this.thumbScroolTop =	this.thumbScroolTop - 100;
      }
      document.getElementById('left-thumb-container').scrollTop = this.thumbScroolTop;
    }
    if(scrollType == "bottom"){
      let topOffset = document.getElementById('inner-element').offsetTop+document.getElementById('left-thumb-container').scrollTop - 350;
      let temp = this.thumbScroolTop + 100;
      if(temp <= topOffset){
        this.thumbScroolTop = this.thumbScroolTop + 100;
        document.getElementById('left-thumb-container').scrollTop = this.thumbScroolTop;
      }
    }
  }

  //slider jumb to
  goToSlide(slideId:number){
    this.owlElement.trigger('to.owl.carousel',[slideId]);
  }
  
  selectColor(colorId:Number){
    this.productSelectedColor = colorId;
  }

  addToRecentlyViewed(productId:any){
    let recentData:any = [];
    if(localStorage.getItem("mitashi_recently_viewed") != "" && localStorage.getItem("mitashi_recently_viewed") != null && localStorage.getItem("mitashi_recently_viewed") != "undefined"){
      recentData = JSON.parse(localStorage.getItem("mitashi_recently_viewed"));
    }
    // localStorage.removeItem("mitashi_recently_viewed");return;
    if(recentData.indexOf(productId) == "-1"){
      recentData.push(productId);
    }else{
      recentData.splice(recentData.indexOf(productId),1);
      recentData.push(productId);
    }
    localStorage.setItem("mitashi_recently_viewed",JSON.stringify(recentData));
  }

  addEditWishList(wishlistedFlag:any,wishlistedId:any){
    if(this.userId != ""){
      if(wishlistedFlag){
        this.commonServices.removeFromWishlist(wishlistedId).subscribe(response=>{
          if(response.status_code == '200'){
            this.wishlistedFlag = !wishlistedFlag;
            this.wishlistedId = 0;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:this.snackbarClasses
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:this.snackbarClasses
            });
          }
        });
      }else{
        this.commonServices.addToWishList(this.productId).subscribe(response=>{
          if(response.status_code == '200'){
            this.wishlistedFlag = !wishlistedFlag;
            this.wishlistedId = response.body.wishlist_id;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:this.snackbarClasses
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:this.snackbarClasses
            });
          }
        });
      }
    }else{
      this.snackBar.open("Please Login for Add this Product To Your Wishlist ", "Close", {
        duration: 3000,
        verticalPosition: 'top',
        panelClass:this.snackbarClasses
      });
      setTimeout(() => {
        this.dialog.open(LoginComponent, {disableClose: false ,
          data: {}
        });
      }, 3000);
    }
  }

  addToCompare(compareProductDetails:any){
    // localStorage.removeItem('compareData');return;
    let compareData = JSON.parse(localStorage.getItem('compareData'));
    console.log()
    let tempArray:any = []; 
    if(compareData != null) {
      let isProductPresent = compareData.some(function(el){ return el.product_id === compareProductDetails['product_id']});
      let isCategoryPresent = compareData.some(function(el){ return el.category_id === compareProductDetails['category_id']});
      if(isProductPresent){
        this.snackBar.open("Product already added for compare ..!", "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:this.snackbarClasses
        });
        return;
      }else if(compareData.length >= 4){
        this.snackBar.open("You reached maximum product for compare ..!", "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:this.snackbarClasses
        });
        return;
      }else if(compareData.length > 0 && !isCategoryPresent){
        this.snackBar.open("You can only Compare Similar Products..!", "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:this.snackbarClasses
        });
        return;
      }else if(compareData.length < 4){
        compareData.push(compareProductDetails);
        tempArray =  compareData;
        this.snackBar.open("Product added for compare..!", "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:this.snackbarClasses
        });
      }
    }else{
      tempArray.push(compareProductDetails);
    }
    localStorage.setItem("compareData",JSON.stringify(tempArray));
    this.productCompareService.changeCompareCount();
  }

  openImageViewer(imageIndex:any){
    this.dialog.open(ImageViewerComponent,{
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '100%',
      data: {
        galleryImagesSlider:this.galleryImagesSlider,
        imageIndex:imageIndex
      }
    });
  }
}
