import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../common/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css','./cart.component.scss']
})
export class CartComponent implements OnInit {
  baseUrl = environment.baseUrl;
  assetsUrl = environment.assetsUrl;
  cartDetails:any ;
  productTotalAmount:any;
  productQuantities:Array<any>;
  productSubTotals:Array<any>;
  productLoading:any = true;
  emptyFlag:boolean = false;
  constructor(
    private cartService:CartService,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private snackBar:MatSnackBar,
    private dialog:MatDialog,
  ) { 
    
    
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      this.getCartDetails();
    })
  }

  getCartDetails(){
    this.cartService.getCartDetails().subscribe(response=>{
      if(response.status_code == "200" ){
        if(response.body.cart_products.length > 0){
          this.cartDetails = response.body.cart_products;
          this.productTotalAmount = this.getTotalOfSubtotals(this.cartDetails);
          this.productLoading = false;
        }else{
          this.cartDetails = [];
          this.productLoading = false;
          this.emptyFlag = true;
        }
      }else{
        this.productLoading = false;
        this.emptyFlag = true;
      }
    });
    this.productLoading = false;
  }

  getTotalOfSubtotals(cartArray:any){
    let Total:any = 0;
    cartArray.forEach(item => {
      Total = Total + (item.product_price * item.quantity);
    });
    return Total;
  }

  substractQuantity(arrayKey:any){
    if(this.cartDetails.length > 0){
      if(this.cartDetails[arrayKey].quantity > 1){
        this.cartDetails[arrayKey].quantity = Number(this.cartDetails[arrayKey].quantity) - 1;
      }
      this.productTotalAmount = this.getTotalOfSubtotals(this.cartDetails);
    }
  }

  addQuantity(arrayKey:any){
    if(this.cartDetails.length > 0){
      this.cartDetails[arrayKey].quantity = Number(this.cartDetails[arrayKey].quantity) + 1;
      this.productTotalAmount = this.getTotalOfSubtotals(this.cartDetails);
    }
  }

  changeColor(arrayKey:any,colorValue:any){
    if(this.cartDetails.length > 0){
      this.cartDetails[arrayKey].product_color = colorValue;
    }
  }
  updateShoppingCart(){
    if(this.cartDetails.length > 0){
      this.cartService.updateAllCartProduct(this.cartDetails).subscribe(response=>{
        this.snackBar.open(response.msg, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:['bg-black']
        });
      });
    }
  }

  removeCartProduct(arrayKey:any){
    if(this.cartDetails.length > 0){
      this.cartService.removeCartProduct(this.cartDetails[arrayKey].cart_id).subscribe(response=>{
        if(response.status_code == "200"){
          let existing_cart_count:any = localStorage.getItem("cartCount");
          existing_cart_count = Number(existing_cart_count)-1;
          localStorage.setItem("cartCount",existing_cart_count);
          this.cartService.changeCartCount();
          this.cartDetails.splice(arrayKey,1); 
          this.productTotalAmount = this.getTotalOfSubtotals(this.cartDetails);
          if(this.cartDetails.length <= 0){
            this.emptyFlag =  true;
          }
        }
        this.snackBar.open(response.msg, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:['bg-black']
        });
      });
    }else{
      this.emptyFlag =  true;
    }
  }

  clearShoppingCart(){
    if(this.cartDetails.length > 0){
      const dialogRef = this.dialog.open(ConfirmDialogComponent,{disableClose: true ,
        width: '250px',
        data: {
          confirmMsg:"Are you sure to clear your Cart ?"
        }
      });
      dialogRef.afterClosed().subscribe(response=>{
        if(response){
          this.cartService.clearCart().subscribe(response=>{
            if(response.status_code == "200"){
              let existing_cart_count:any = localStorage.getItem("cartCount");
              existing_cart_count = Number(existing_cart_count)-1;
              localStorage.setItem("cartCount",existing_cart_count);
              this.cartService.changeCartCount();
              this.cartDetails=[]; 
            }
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          });
        }
      });
    }
  }

  proceedToCheckout(){
    if(this.cartDetails.length > 0){
      this.cartService.updateAllCartProduct(this.cartDetails).subscribe(response=>{
        if(response.status_code == "200"){
          this.router.navigate(['checkout']);
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      });
    }
  }
}


