import { Directive, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from '@angular/forms';
import { Subscriber, Subscription } from 'rxjs';


@Directive({
  selector: '[notEqual]',
  providers:[{provide:NG_VALIDATORS,useExisting:NotEqualDirective,multi:true}]
})
export class NotEqualDirective implements Validator{
  @Input('notEqual') controlNameToCompare:string;
  validate(control: AbstractControl): ValidationErrors {
    const controlToCompare = control.root.get(this.controlNameToCompare);
    if(controlToCompare){ 
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(()=>{
        control.updateValueAndValidity();
        subscription.unsubscribe();   
      })
    }
    return controlToCompare && controlToCompare.value == control.value ? {'notEqual':true} : null;
  }

}
