import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener, ɵConsole, AfterViewInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormInterface } from 'src/app/models/from-interface';
import { trigger, transition, style, animate } from '@angular/animations';
import { CommonService } from 'src/app/services/common.service';
import { MatTabGroup, MatTabChangeEvent, MatDialog,MatSnackBar } from '@angular/material';
import { ModelDialogComponent } from '../common/model-dialog/model-dialog.component';
import { AddAddressDialogComponent } from './add-address-dialog/add-address-dialog.component';
import { ConfirmDialogComponent } from '../common/confirm-dialog/confirm-dialog.component';
import { CartService } from 'src/app/services/cart.service';
import { AuthService } from 'src/app/services/auth.service';




@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css','./my-account.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms ease-in', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('500ms ease-in', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class MyAccountComponent implements AfterViewInit {
  @ViewChild("changePasswordSection") changePasswordSection:ElementRef;
  @ViewChild("matTabElement") matTabElement:ElementRef
  @ViewChild("matAccordienElement") matAccordienElement:ElementRef
  accountInformationForm:FormGroup;
  addressForm:FormGroup;
  accountFormSubmitted:boolean =  false;
  addressFormSubmitted:boolean = false;
  changePassword:boolean = false;
  mobile_screen:boolean = false;
  userAccountDetails:any;
  wishlistDetails:any;
  newsletterDetails:any;
  ordersDetials:any;
  alertDanger:string = "";
  alertSuccess:string = "";
  tabGroup:MatTabGroup;
  selectedTab :any;
  accountDetails:FormInterface;
  addressDetails:any = "";
  addressId:string = "";
  userId:any;
  loading:any =false;
  accordian_step:any = 0;
  guestUserFlag:boolean = false;
  manageAddressTab = 2;
  

  constructor(
    private formBuilder:FormBuilder,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private commonService: CommonService,
    public dialog:MatDialog,
    private cartService:CartService,
    private snackBar:MatSnackBar,
    private authService:AuthService
    ) { 
    
    this.activatedRoute.params.subscribe((params)=>{
      if(params.tab == "orders"){
        this.setStep(1);
      }
      this.getAccountDetails();
    })
  }

  ngAfterViewInit(): void {
    let user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if(user_data == null) {
      this.router.navigate(['/']); 
    }
  }

  ngOnInit() {
    if (window.screen.width <= 800) { 
      this.mobile_screen = true;
    }
  }

  getAccountDetails(){
    if(localStorage.getItem("mitashiUser")){
      this.accountInformationForm = this.formBuilder.group({
        first_name:['',[Validators.required]],
        middle_name:['',[Validators.required]],
        last_name:['',[Validators.required]],
        email_id:[''],
        contact_number:['',[Validators.required,Validators.pattern("^[0-9]*")]],
        change_password_check:[''],
        current_password:[''],
        new_password:[''],
        confirm_password:['']
      });
      
      let current_user = JSON.parse(localStorage.getItem("mitashiUser"));
      this.userId = current_user[0].user_id;
      if(current_user[0].user_type == "Guest"){
        this.guestUserFlag = true;
        this.manageAddressTab = 1;
      }else{
        this.manageAddressTab = 2;
        this.guestUserFlag = false;
      }
      
      this.commonService.getAccountDetails(this.userId).subscribe((response)=>{
        if(response.status_code == "200"){
          this.accountDetails = response.body;
          this.accountInformationForm.controls['first_name'].setValue(response.body.contact_details[0].first_name);
          this.accountInformationForm.controls['middle_name'].setValue(response.body.contact_details[0].middle_name);
          this.accountInformationForm.controls['last_name'].setValue(response.body.contact_details[0].last_name);
          this.accountInformationForm.controls['email_id'].setValue(response.body.contact_details[0].email_id);
          this.accountInformationForm.controls['contact_number'].setValue(response.body.contact_details[0].phone_no);
          this.ordersDetials = response.body.order_details;
          this.wishlistDetails = response.body.wishlist_details;
          this.newsletterDetails = response.body.newsletter_subscription;
        }else{
          console.log(response.msg);
        }
      });

      this.commonService.getAddress(this.userId).subscribe((response)=>{
        if(response.status_code == "200"){
          this.addressDetails = response.body.allAddress;
        }else{
          this.addressDetails = "";
        }
      })

    }else{
      
    }
  }

  
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    if (event.target.innerWidth <= 800) { // 800 portrait
      this.mobile_screen = true;
    }else{
      this.mobile_screen = false;
    }
  }

  setStep(index: number) {
    this.accordian_step = index;
  }

  nextStep() {
    this.accordian_step++;
  }

  prevStep() {
    this.accordian_step--;
  }

  myAccountTabChange(){
    this.ngOnInit();
    this.alertDanger = "";
    this.alertSuccess = "";
    this.accountFormSubmitted = false;
  }

  onSubmit(formName:string){
    if(formName == "accountInformationForm"){
      this.addressFormSubmitted = false; 
      this.accountFormSubmitted = true;
      console.log("form-status",this.accountInformationForm.valid);
      if(this.accountInformationForm.valid){
        let current_user = JSON.parse(localStorage.getItem("mitashiUser"));
        let userId:any = current_user[0].user_id;
        this.commonService.saveUserProfileInfo(this.accountInformationForm.value,userId).subscribe(response=>{
          if(response.status_code == "200"){
            this.alertSuccess = response.msg;
            this.alertDanger = "";
            this.changePassword = false;
            this.accountInformationForm.controls['current_password'].setValue("");
            this.accountInformationForm.controls['new_password'].setValue("");
            this.accountInformationForm.controls['confirm_password'].setValue("");
            setTimeout(() => {
              this.alertSuccess = "";
              this.router.navigate(['/my-account']); 
            }, 3000);
          }else{
            this.alertDanger = response.msg;
            this.alertSuccess = "";
          }
        })
      }else{
        return;
      }
    }else if(formName == "addressForm"){
      this.addressFormSubmitted = true; 
      this.accountFormSubmitted = false;
    }
  }
 

  changePasswordCheckbox(){
    this.accountInformationForm.controls['current_password'].setValue("");
    this.accountInformationForm.controls['new_password'].setValue("");
    this.accountInformationForm.controls['confirm_password'].setValue("");
    this.changePassword = this.accountInformationForm.value.change_password_check;
    if(this.changePassword == false){
      this.accountInformationForm.controls['current_password'].reset
      this.accountInformationForm.controls['new_password'].reset
      this.accountInformationForm.controls['confirm_password'].reset
    }
  }
  
  addAddressDialog(addressId:string = "") {
    this.dialog.open(AddAddressDialogComponent, {disableClose: true ,
      data: {
        addressId:addressId
      }
    });
  }

  makeDefaultAddress(addressId:string,isDefault:string){
    let radioFlag:boolean = false;
    if(isDefault == "No"){
      const dialogRef = this.dialog.open(ConfirmDialogComponent,{disableClose: true ,
        width: '250px',
        data: {
          confirmMsg:"Are you sure to Make this Address as Default Address ?"
        }
      });
      dialogRef.afterClosed().subscribe(response=>{
        if(response){
          radioFlag = response;
          this.commonService.makeDefaultAddress(addressId,this.userId).subscribe(response=>{
            if(response.status_code == "200"){
              this.dialog.open(ModelDialogComponent, {
                width:'250px',
                data: {
                  alertMsg:"The selected Address changed as Default Address Successfully"
                }
              });
              this.router.navigate(['/my-account']); 
              this.dialog.closeAll();
            }else{
              console.log(response.msg);
            }
          })
        }else{
          return;
        }
      })
      if(!radioFlag){
        return false;
      }
    }else{
      this.dialog.open(ModelDialogComponent, {
        width: '250px',
        data: {
          alertMsg:"The Address Already set as a Default Address"
        }
      });
    }
  }

  deleteAddressDialog(addressId:string){
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{disableClose: true ,
      width: '250px',
      data: {
        confirmMsg:"Are you sure to Make this Address as Default Address ?"
      }
    });
    dialogRef.afterClosed().subscribe(response=>{
      if(response){
        this.commonService.deleteAddress(addressId,this.userId).subscribe(response=>{
          if(response.status_code == "200"){
            this.dialog.open(ModelDialogComponent, {
              width:'250px',
              data: {
                alertMsg:"The Address Deleted Successfully"
              }
            });
            setTimeout(() => {
              this.router.navigate(['/my-account']); 
              this.dialog.closeAll();
            }, 2000);
          }else{
            console.log(response.msg);
          }
        })
      }
    })
  }

  addToCartFromWishlist(wishlistId:any,arrayKey:any){
    if(this.wishlistDetails.length > 0){
      this.cartService.addToCartFromWishlist(wishlistId).subscribe((response)=>{
        if(response.status_code == "200"){
          this.wishlistDetails.splice(arrayKey,1);
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
          this.cartService.changeCartCount();
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      });
    }else{
      this.snackBar.open("Your Wishlist is Empty..!", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
    }
  }
  
  addAllToCartFromWishlist(){
    if(this.wishlistDetails.length > 0){
      const dialogRef = this.dialog.open(ConfirmDialogComponent,{disableClose: true ,
        width: '250px',
        data: {
          confirmMsg:"Are you sure to Add All the Products To Cart ?"
        }
      });
      dialogRef.afterClosed().subscribe(response=>{
        if(response){
          this.cartService.addAllToCartFromWishlist().subscribe((response)=>{
            if(response.status_code == "200"){
              this.wishlistDetails=[];
              this.snackBar.open("Products added to Wishlist..!", "Close", {
                duration: 2000,
                verticalPosition: 'top',
                panelClass:['bg-black']
              });
              this.cartService.changeCartCount();
            }else{
              this.snackBar.open(response.msg, "Close", {
                duration: 2000,
                verticalPosition: 'top',
                panelClass:['bg-black']
              });
            }
          });
        }
      });
    }else{  
      this.snackBar.open("Your Wishlist is Empty..!", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
    }
  }

  removeProductFromWishlist(wishlistId:any,arrayKey:any){
    if(this.wishlistDetails.length > 0){
      this.commonService.removeProductFromWishlist(wishlistId).subscribe((response)=>{
        if(response.status_code == "200"){
          this.wishlistDetails.splice(arrayKey,1);
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
          this.cartService.changeCartCount();
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      });
    }else{
      this.snackBar.open("Your Wishlist is Empty..!", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
    }
  }

  changeColor(arrayKey:any,colorValue:any){
    if(this.wishlistDetails.length > 0){
      this.wishlistDetails[arrayKey].color_id = colorValue;
    }
  }

  updateWishlist(){
    if(this.wishlistDetails.length > 0){
      this.commonService.updateWishlist(this.wishlistDetails).subscribe((response)=>{
        if(response.status_code == "200"){
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      })
    }
  }
  newsLetterSubscribe(){
    let user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if(user_data != null) {
      this.commonService.newsletterSubscription(user_data[0]['email_id']).subscribe((response)=>{
        if(response.status_code == "200"){
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
          this.newsletterDetails = true;
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      });
    }else{
      this.snackBar.open("Please Login..!", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
    }
  }

  newsLetterUnSubscribe(){
    let user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if(user_data != null) {
      this.commonService.newsLetterUnSubscribe(user_data[0]['email_id']).subscribe((response)=>{
        if(response.status_code == "200"){
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
          this.newsletterDetails = false;
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      });
    }else{
      this.snackBar.open("Please Login..!", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
    }
  }
}
