import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LoginComponent } from 'src/app/components/login/login.component';
import { StarRatingComponent } from 'ng-starrating';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-product-review',
  templateUrl: './list-product-review.component.html',
  styleUrls: ['./list-product-review.component.css']
})
export class ListProductReviewComponent implements OnInit {
  assetsUrl:string = environment.assetsUrl;
  baseUrl:string = environment.baseUrl;
  reviewForm:FormGroup;
  reviewFormSubmit:boolean = false;
  reviewNotAvailableFlag:boolean = false;
  productLink:string;
  productDetails:any;
  reviewDetails:any;
  productImage:string;
  productId:string;
  productName:string;
  productPrice:string;
  productReviewDetails:any;
  overAllRating:Number = 0;
  totalReviewCount:Number = 0;
  rating1:Number = 0;
  rating2:Number = 0;
  rating3:Number = 0;
  rating4:Number = 0;
  rating5:Number = 0;
  ratingDatas:any;
  pageOffset:any = 0;
  itemsPerPage:any = 15;
  currentPage:any;
  totalProducts:any;
  productLoading:boolean = false;
  constructor(
    private formBuilder:FormBuilder,
    private dialog:MatDialog,
    private commonService:CommonService,
    private activatedRoute:ActivatedRoute,
    private snackBar:MatSnackBar
  ) { 
    this.activatedRoute.params.subscribe((params)=>{
      if(params.product_link != ""){
        this.productLink = params.product_link
        this.getAllReviews();
      }
    })
  }

  ngOnInit( ) {
  }

  getAllReviews(){
    this.productLoading = true;
    this.commonService.getAllReviews(this.productLink,this.pageOffset,this.itemsPerPage).subscribe((response)=>{
      if(response.status_code == "200"){
        this.reviewNotAvailableFlag = false;
        this.productDetails = response.body.product_details;
        if(this.productDetails.length>0){
          this.productImage = this.productDetails[0]['product_big_image_url'];
          this.productName = this.productDetails[0]['product_name'];
          this.productPrice = this.productDetails[0]['product_price'];
          this.productId = this.productDetails[0]['product_id'];
        }
        //productReviewDetails
        this.productReviewDetails = response.body.product_reviews;
        if(this.productReviewDetails){
          if(response.body.reviews_rating_datas.length>0){
            this.ratingDatas = response.body.reviews_rating_datas;
            this.overAllRating = this.ratingDatas[0].overAll;
            this.totalReviewCount = this.ratingDatas[0].total_user;
            this.totalProducts = this.totalReviewCount;
            this.rating1 = ( this.ratingDatas[0].rating_wise_percent['1'] != "undefined")?this.ratingDatas[0].rating_wise_percent['1']:0;
            this.rating2 = (this.ratingDatas[0].rating_wise_percent['2'] != "undefined")?this.ratingDatas[0].rating_wise_percent['2']:0;
            this.rating3 = (this.ratingDatas[0].rating_wise_percent['3'] != "undefined")?this.ratingDatas[0].rating_wise_percent['3']:0;
            this.rating4 = (this.ratingDatas[0].rating_wise_percent['4'] != "undefined")?this.ratingDatas[0].rating_wise_percent['4']:0;
            this.rating5 = (this.ratingDatas[0].rating_wise_percent['5'] != "undefined")?this.ratingDatas[0].rating_wise_percent['5']:0;
          }
        }
        this.productLoading = false;
      }else{
        this.productLoading = false;
      }
    })
  }

}
