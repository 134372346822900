import { Directive ,Input} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[requiredIf]',
  providers:[{provide:NG_VALIDATORS,useExisting:RequiredifDirective,multi:true}]
})
export class RequiredifDirective implements Validator {
  @Input('requiredIf')
  requiredIf:boolean;
  validate(control:AbstractControl){
    let value = control.value;
    if((value == null || value == undefined || value == "") && this.requiredIf){
      return{
        requiredIf:{
          condition:this.requiredIf
        }
      }
    }
    return null;
  }

}
