import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})

export class CheckoutGuard implements CanActivate {
  constructor(
    private router:Router
  ){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      let cartCount:any = localStorage.getItem("cartCount")
    if(cartCount <= 0){
        this.router.navigate(['cart']);
        return false;
    }
    return true;
  }
}
