import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpInterface } from '../models/http-interface';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  @Output() cartCount: EventEmitter<any> = new EventEmitter();
  apiUrl = environment.apiUrl;
  apiUrlTemp = environment.apiUrlTemp;
  constructor(
    private http:HttpClient
  ) {
  }

  changeCartCount() {
    if(localStorage.getItem("cartCount") != null){
      const temp_count = localStorage.getItem("cartCount")
      this.cartCount.emit(temp_count);
    }else{
      this.cartCount.emit("0");
    }
  }

  updateCartValue() {
    return this.cartCount;
  }

 
  getCartDetails(){
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    const formData = new FormData();
    formData.append("cartSession",localStorage.getItem("cart_session"));
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    return this.http.post<HttpInterface>(this.apiUrlTemp+"getCartDetails",formData);
  }

  addToCart(productId:any,colorId:any){
    const formData = new FormData();
    formData.append("productId",productId);
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("cartSession",localStorage.getItem("cart_session"));
    formData.append("colorId",colorId);
    formData.append("quantity","1");
    return this.http.post<HttpInterface>(this.apiUrlTemp+"addToCart",formData);
  }

  updateAllCartProduct(cartData:any){
    const formData = new FormData();
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("cartData",JSON.stringify(cartData));
    formData.append("cartSession",localStorage.getItem("cart_session"));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"updateAllCartProduct",formData);
  }

  removeCartProduct(cartId:any){
    const formData = new FormData();
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    formData.append("cartId",cartId);
    formData.append("cartSession",localStorage.getItem("cart_session"));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"removeCartProduct",formData);
  }

  clearCart(){
    const formData = new FormData();
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    formData.append("cartSession",localStorage.getItem("cart_session"));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"clearCart",formData);
  }

  addToCartFromWishlist(wishlistId:any){
    const formData = new FormData();
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("cartSession",localStorage.getItem("cart_session"));
    formData.append("wishlistId",wishlistId);
    return this.http.post<HttpInterface>(this.apiUrlTemp+"addToCartFromWishlist",formData);
  }

  addAllToCartFromWishlist(){
    const formData = new FormData();
    if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
      let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
      localStorage.setItem("cart_session",localStorageUniqueData);
    }
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      formData.append("userId",$user_data[0]['user_id']);
    }
    formData.append("cartSession",localStorage.getItem("cart_session"));
    return this.http.post<HttpInterface>(this.apiUrlTemp+"addAllToCartFromWishlist",formData);
  }
}
