import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface DialogData {
  alertMsg:any;
}
@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'model-dialog.component.html',
  styleUrls: ['./model-dialog.component.css']
})
export class ModelDialogComponent {
  alertMsg:any ;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef:MatDialogRef<ModelDialogComponent>
  ) {
    this.alertMsg = data.alertMsg;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}