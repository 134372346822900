import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LoginComponent } from 'src/app/components/login/login.component';
import { StarRatingComponent } from 'ng-starrating';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-product-review',
  templateUrl: './add-product-review.component.html',
  styleUrls: ['./add-product-review.component.css']
})
export class AddProductReviewComponent implements OnInit {
  assetsUrl:string = environment.assetsUrl;
  baseUrl:string = environment.baseUrl;
  reviewForm:FormGroup;
  reviewFormSubmit:boolean = false;
  reviewNotAvailableFlag:boolean = false;
  productLink:string;
  productDetails:any;
  reviewDetails:any;
  productImage:string;
  productId:string;
  productName:string;
  productPrice:string;
  constructor(
    private formBuilder:FormBuilder,
    private dialog:MatDialog,
    private commonService:CommonService,
    private activatedRoute:ActivatedRoute,
    private snackBar:MatSnackBar
  ) { 
    this.activatedRoute.params.subscribe((params)=>{
      if(params.product_link != ""){
        this.productLink = params.product_link
        this.getReviewAccess();
      }
    })
  }

  ngOnInit() {
    this.reviewForm = this.formBuilder.group({
      Title:   ['', Validators.required],
      Comments: ['', Validators.required],
      Rating:   ['', Validators.required],
      productId: [this.productId],
    })
    
  }

  getReviewAccess(){
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      this.commonService.getReviewAccess(this.productLink).subscribe((response)=>{
        if(response.status_code == "200"){
          this.reviewNotAvailableFlag = false;
          this.productDetails = response.body.order_details;
          if(this.productDetails.length>0){
            this.productImage = this.productDetails[0]['product_big_image_url'];
            this.productName = this.productDetails[0]['product_name'];
            this.productPrice = this.productDetails[0]['product_price'];
            this.productId = this.productDetails[0]['product_id'];
          }
          this.reviewDetails = response.body.review_details;
          if(this.reviewDetails.length > 0){
            this.reviewForm.controls['Title'].setValue(this.reviewDetails[0]['review_title']);
            this.reviewForm.controls['Comments'].setValue(this.reviewDetails[0]['review_content']);
            this.reviewForm.controls['Rating'].setValue(this.reviewDetails[0]['rating']);
            this.productId = this.reviewDetails[0]['product_id'];
          }
        }else{
          this.reviewNotAvailableFlag = true;
        }
      })
    }else{
      this.dialog.open(LoginComponent, {disableClose: false ,
        data: {}
      });
      this.reviewNotAvailableFlag = true;
    }
  }
  onSubmit(){
    let $user_data = JSON.parse(localStorage.getItem('mitashiUser'));
    if($user_data != null) {
      this.reviewFormSubmit = true;
      if(this.reviewForm.invalid){
        return;
      }
      this.commonService.addReview(this.reviewForm.value).subscribe((response)=>{
        if(response.status_code == "200"){
          this.snackBar.open(response.msg, "Close", {
            duration: 3000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
          this.reviewFormSubmit = false;
        }else{
          this.snackBar.open(response.msg, "Close", {
            duration: 3000,
            verticalPosition: 'top',
            panelClass:['bg-black']
          });
        }
      })
    }else{
      this.dialog.open(LoginComponent, {disableClose: false ,
        data: {}
      });
    }
  }

  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    this.reviewForm.controls['Rating'].setValue($event.newValue);  
    // alert(`Old Value:${$event.oldValue}, 
    //   New Value: ${$event.newValue}, 
    //   Checked Color: ${$event.starRating.checkedcolor}, 
    //   Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
}
