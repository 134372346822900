import { Component } from '@angular/core';
import {Event, ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';
import { ProductCompareService } from './services/product-compare.service';
@Component({
  selector: 'app-root',
  templateUrl: 'components/common/layout/layout.component.html',
  styleUrls: ['components/common/layout/layout.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':leave', [   // :leave is alias to '* => void'
        animate('1000ms ease-out', style({opacity:2})) 
      ])
    ]),
    trigger('enterAnimation', [
      transition(':enter', [
        style({transform: 'translateY(100%)', opacity: 0}),
        animate('500ms ease-in', style({transform: 'translateY(0)', opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translateY(0)', opacity: 1}),
        animate('500ms ease-in', style({transform: 'translateY(100%)', opacity: 0}))
      ])
    ])
  ]
  })
export class AppComponent {
  title = 'mitashi';
  loading = true;
  constructor(
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private compareService:ProductCompareService,
    ) {
      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loading = true;
            break;
          }
          case event instanceof NavigationEnd:{
            this.loading = true;
            this.compareService.changeCompareFlag();
          }
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
          // case event instanceof NavigationEnd: {
            setTimeout(() => {
              this.loading = false;
            }, 1000);
            break;
          }
          default: {
            break;
          }
        }
      });
   }

}

