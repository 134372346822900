import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, ReactiveFormsModule, Validators  } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { trigger, transition, animate, style, query, group } from '@angular/animations';
import { CartService } from 'src/app/services/cart.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity:0 }),
        animate('1000ms ease-in-out', style({ opacity:1 }))
      ]),
      transition(':leave', [
        style({ opacity:1 }),
        animate('0ms ease-in-out', style({ opacity:0 }))
      ]),
    ])
  ],
  styles: [
    `:host {
        height: 300px; 
        overflow: auto;
      }
    `  
  ],
})
export class LoginComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  loginForm: FormGroup;
  registerForm: FormGroup;
  guestloginForm:FormGroup;
  forgotPasswordForm:FormGroup;
  loginSubmitted = false;
  registerSubmitted = false;
  guestLoginSubmitted = false;
  forgotPasswordSubmitted = false;
  logoutAlert = false;
  returnUrl:string;
  alertMsg:boolean;
  alertMsgSuccess:string = "";
  alertMsgError:string = "";
  alertRegisterMsgSuccess:string = "";
  alertRegisterMsgError:string = "";
  alertGuestMsgSuccess:string = "";
  alertGuestMsgError:string = "";
  alertForgotMsgError : string = "";
  alertForgotMsgSuccess  : string = "";
  LoginResgisterFlag:boolean = true;
  loginFlag:boolean =true;
  registerFlag:boolean = false;
  guestFlag:boolean = false;
  productLoading:boolean = false;
  forgotFlag:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private commonService:CommonService,
    private authService:AuthService,
    private activatedRouter:ActivatedRoute,
    private router:Router,
    private cartService:CartService,
    private dialog:MatDialog,
    public dialogRef: MatDialogRef<LoginComponent>,
  ) { 
    
    this.activatedRouter.queryParams.subscribe((params:any)=>{
      if(params.logout === "logout" ){
        this.logoutAlert = true;
      }
    })
  }

  ngOnInit() {
    //check lready logged in
    this.authService.isUserLoggedIn();

    this.loginForm = this.formBuilder.group({
      username: ['',  [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.registerForm = this.formBuilder.group({
      firstName:   ['', Validators.required],
      middleName:  [''],
      lastName:    ['', Validators.required],
      emailId:     ['', [Validators.required, Validators.email]],
      contactNo:   ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      password:     ['', Validators.required],
      cnfPassword: ['', Validators.required]
    },{
      
    });
     
    this.guestloginForm = this.formBuilder.group({
      email_id: ['',  [Validators.required, Validators.email]]
    });

    this.forgotPasswordForm = this.formBuilder.group({
      forgot_email_id: ['',  [Validators.required, Validators.email]]
    });

    
  }

  loginAction(pageFlag:string){
    this.alertMsgSuccess = "";
    this.alertMsgError = "";
    this.alertRegisterMsgSuccess = "";
    this.alertRegisterMsgError = "";
    this.alertGuestMsgSuccess = "";
    this.alertGuestMsgError = "";
    this.alertForgotMsgError = "";
    this.alertForgotMsgSuccess = "";
    if(pageFlag == "guest"){
      this.registerFlag = this.loginFlag = this.forgotFlag =  false; 
      this.guestFlag =  true;
    }else if(pageFlag == "login"){
      this.registerFlag = this.guestFlag = this.forgotFlag =  false; 
      this.loginFlag =  true;
    }else if(pageFlag == "register"){
      this.loginFlag = this.guestFlag = this.forgotFlag =  false; 
      this.registerFlag =  true;
    }else if(pageFlag == "forgotpassword"){
      this.loginFlag = this.guestFlag =  this.registerFlag  = false ; 
      this.forgotFlag =  true;
    }
  }

  onSubmit(formType:string) {
    this.logoutAlert = false;
    
    if(formType == "login"){
      this.loginSubmitted = true;
      this.registerSubmitted = false;
      this.guestLoginSubmitted = false;
      this.forgotPasswordSubmitted =false;
      this.alertRegisterMsgSuccess = this.alertRegisterMsgError = "";
      if(this.loginForm.invalid) {
        return;
      }
      this.productLoading = true;
      this.authService.loginUser(this.loginForm.value.username,this.loginForm.value.password).subscribe((response:any)=>{
        if(response.status_code == "200"){
          localStorage.setItem('mitashiUser',JSON.stringify(response.body.user_details));
          this.alertMsgSuccess = response.msg;
          this.alertMsgError = "";
          this.cartService.changeCartCount();
          setTimeout(() => {
              this.onNoClick(true);
          }, 2000);
        }else{
          this.alertMsgError = response.msg;
          this.alertMsgSuccess = "";
        }
        this.productLoading = false;
      });
    }else if(formType == "register"){
      this.registerSubmitted = true;
      this.loginSubmitted = false;
      this.guestLoginSubmitted = false;
      this.alertMsgSuccess = this.alertMsgError = "";
      if(this.registerForm.invalid) {
         return;
      }
      this.productLoading = true;
      this.authService.userRegister(this.registerForm.value).subscribe(response=>{
        if(response.status_code == "200"){
          this.alertRegisterMsgSuccess =  response.msg;
          this.alertRegisterMsgError = "";
          this.registerForm.reset();
          this.registerSubmitted=false;
          this.loginAction("login");
        }else{
          this.alertRegisterMsgError = response.msg;
          this.alertRegisterMsgSuccess = "";
        }
        this.productLoading = false;
      });
    }else if(formType == "guestLogin"){
      this.guestLoginSubmitted = true;
      this.registerSubmitted = false;
      this.loginSubmitted = false;
      this.forgotPasswordSubmitted =false;
      this.alertGuestMsgSuccess = this.alertGuestMsgError = "";
      if(this.guestloginForm.invalid) {
        return;
      }
      this.productLoading = true;
      this.authService.guestUserlogin(this.guestloginForm.value.email_id).subscribe((response:any)=>{
        if(response.status_code == "200"){
          localStorage.setItem('mitashiUser',JSON.stringify(response.body.user_details));
          this.alertGuestMsgSuccess = response.msg;
          this.alertGuestMsgError = "";
          this.cartService.changeCartCount();
          setTimeout(() => {
            this.onNoClick(true);
          }, 2000);
        }else{
          this.alertGuestMsgError = response.msg;
          this.alertGuestMsgSuccess = "";
        }
        this.productLoading = false;
      });
    }else if(formType == "forgotpassword"){
      this.guestLoginSubmitted = false;
      this.registerSubmitted = false;
      this.loginSubmitted = false;
      this.forgotPasswordSubmitted =true;
      this.alertForgotMsgSuccess = this.alertForgotMsgError = "";
      if(this.forgotPasswordForm.invalid) {
        return;
      }
      this.productLoading = true;
      this.authService.forgotPassword(this.forgotPasswordForm.value.forgot_email_id).subscribe((response:any)=>{
        if(response.status_code == "200"){
          this.alertForgotMsgSuccess = response.msg;
          setTimeout(() => {
            this.onNoClick(true);
          }, 2000);
        }else{
          this.alertForgotMsgError = response.msg;
          this.alertForgotMsgSuccess = "";
        }
        this.productLoading = false;
      });
    }
  }
  onNoClick(isLoggedin:any = false): void {
    this.dialogRef.close();
    if(isLoggedin){
      this.router.navigate([this.router.url]);
    }
  }
}
