import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressFilter'
})
export class AddressFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    value.forEach((element:any) => {
      if(element.default_add == "Yes"){
        return value;
      }else{
        return false;
      }
    });
  }
}
