import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { addressDetails, FormInterface } from 'src/app/models/from-interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-add-address-dialog',
  templateUrl: './add-address-dialog.component.html',
  styleUrls: ['./add-address-dialog.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms ease-in', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('500ms ease-in', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})
export class AddAddressDialogComponent implements OnInit {
  addressForm:FormGroup;
  sameShippingAddress:boolean = false;
  addressFormSubmitted:boolean = false; 
  accountFormSubmitted:boolean = false;
  addressId:string = "";
  userId:string;
  alertSuccess:string = "";
  alertDanger:string = "";

  constructor(
    public dialogRef: MatDialogRef<AddAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:addressDetails,
    private formBuilder:FormBuilder ,
    public dialog: MatDialog,
    public commonService:CommonService,
    public router:Router,
  ) { 
    let current_user = JSON.parse(localStorage.getItem("mitashiUser"));
    this.userId = current_user[0].user_id;
    this.addressId = this.data.addressId;
  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      userId:[this.userId],
      addressId:[this.addressId],
      billAddressTitle:['',[Validators.required]],
      billName:['',[Validators.required]],
      billEmail:['',[Validators.required,Validators.email]],
      billAddress1:['',[Validators.required]],
      billAddress2:[''],
      billLandmark:[''],
      billCity:['',[Validators.required]],
      billState:['',[Validators.required]],
      billCountry:['',[Validators.required]],
      billZipcode:['',[Validators.required,Validators.pattern("^[0-9]*")]],
      billPhone:['',[Validators.required,Validators.pattern("^[0-9]*")]],
      sameaddressFlag:[''],
      shipAddressTitle:[''],
      shipName:[''],
      shipEmail:['',[Validators.email]],
      shipAddress1:[''],
      shipAddress2:[''],
      shipLandmark:[''],
      shipCity:[''],
      shipState:[''],
      shipCountry:[''],
      shipZipcode:[''],
      shipPhone:[''],
    });
    if(this.addressId != ""){
      this.commonService.getAddress(this.userId,this.addressId).subscribe((response)=>{
        if(response.status_code == "200"){
          this.addressForm.controls['billAddressTitle'].setValue(response.body.allAddress[0].address_title);
          this.addressForm.controls['billName'].setValue(response.body.allAddress[0].name_add);
          this.addressForm.controls['billEmail'].setValue(response.body.allAddress[0].email_id);
          this.addressForm.controls['billAddress1'].setValue(response.body.allAddress[0].street_add);
          this.addressForm.controls['billAddress2'].setValue(response.body.allAddress[0].street_add2);
          this.addressForm.controls['billLandmark'].setValue(response.body.allAddress[0].landmark_add);
          this.addressForm.controls['billCity'].setValue(response.body.allAddress[0].city_add);
          this.addressForm.controls['billState'].setValue(response.body.allAddress[0].state_add);
          this.addressForm.controls['billCountry'].setValue(response.body.allAddress[0].country_add);
          this.addressForm.controls['billZipcode'].setValue(response.body.allAddress[0].pincode_add);
          this.addressForm.controls['billPhone'].setValue(response.body.allAddress[0].phone_add);
          if(response.body.allAddress[0].same_address != "Yes"){
            this.sameShippingAddress = false;
            this.addressForm.controls['shipAddressTitle'].setValue(response.body.allAddress[0].shipping_address_title);
            this.addressForm.controls['shipName'].setValue(response.body.allAddress[0].shipping_name_add);
            this.addressForm.controls['shipEmail'].setValue(response.body.allAddress[0].shipping_email_id);
            this.addressForm.controls['shipAddress1'].setValue(response.body.allAddress[0].shipping_street_add);
            this.addressForm.controls['shipAddress2'].setValue(response.body.allAddress[0].shipping_street_add2);
            this.addressForm.controls['shipLandmark'].setValue(response.body.allAddress[0].shipping_landmark_add);
            this.addressForm.controls['shipCity'].setValue(response.body.allAddress[0].shipping_city_add);
            this.addressForm.controls['shipState'].setValue(response.body.allAddress[0].shipping_state_add);
            this.addressForm.controls['shipCountry'].setValue(response.body.allAddress[0].shipping_country_add);
            this.addressForm.controls['shipZipcode'].setValue(response.body.allAddress[0].shipping_pincode_add);
            this.addressForm.controls['shipPhone'].setValue(response.body.allAddress[0].shipping_phone_add);
          }else{
            this.addressForm.controls['sameaddressFlag'].setValue(true);
            this.sameShippingAddress = true;
          }
        }
      });
    }
  }

  onSubmit(formName:string){
    if(formName == "addressForm"){
      this.addressFormSubmitted = true; 
      this.accountFormSubmitted = false;
      if(this.addressForm.valid){
        // console.log(this.addressForm);
        this.commonService.addUpdateAddress(this.addressForm.value,this.addressId).subscribe((response)=>{
          if(response.status_code == "200"){
            this.alertSuccess = response.msg;
            this.alertDanger = "";
            setTimeout(() => {
              this.addressForm.reset();
              this.alertSuccess = "";
              this.dialogRef.close();
              this.router.navigate([this.router.url]);
            }, 3000);
          }else{
            this.alertDanger = response.msg;
            this.alertSuccess = "";
          }
        })
      }else{
        return;
      }
    }
  }

  changeAddressCheckbox(){
    this.sameShippingAddress = this.addressForm.value.sameaddressFlag;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
