import { Component, OnInit, Injectable, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, ParamMap, Router, Route } from '@angular/router';
import { FormGroup, FormBuilder} from '@angular/forms';
import {MatSnackBar, MatDialog} from '@angular/material';
import { CartService } from 'src/app/services/cart.service';
import { LoginComponent } from '../login/login.component';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css','./products.component.scss']
})
export class ProductsComponent implements OnInit {
  @ViewChild('filterForm') form: FormGroup;
  @ViewChild("allProductDiv") allProductDiv: ElementRef;
  @ViewChild("priceRangeSlider") priceRangeSlider: ElementRef;
  assetsUrl = environment.assetsUrl;
  baseUrl = environment.baseUrl;
  home_banner:any;
  allProducts:any = {};
  paramsSubscription:any;
  category_link:string;
  subcategory_link:string;
  pageOffset:any = 1;
  itemsPerPage:any = 12;
  currentPage:any;
  totalProducts:any;
  productSort:string;
  minValue: number;
  maxValue: number;
  option:any;
  categoryFilter:any;
  categoryFilterChecked:any = (JSON.parse(localStorage.getItem("categoryFilter")) != null)?JSON.parse(localStorage.getItem("categoryFilter")):[];
  priceFilter:any;
  test:any;
  filterForm:FormGroup;
  ngForm:FormGroup;
  priceSliderValue:any;
  productLoading:any = false;
  minValueInput:number = 0;
  maxValueInput:number = 100;
  subCategories:any;
  whishListData:any;
  userId:any = 0;
  bannerNavArrowLeft:string = this.assetsUrl+"images/left-arrow.png";
  bannerNavArrowRight:string = this.assetsUrl+"images/right-arrow.png";
  bannerOptions:any = {
    items: 1,
    dots: false,
    nav:true,
    navigation: false,
    autoplay:true,
    loop:true,
    arrow:true,
    autoplaySpeed:2000,
    navText: ["<img src="+this.bannerNavArrowLeft+">", "<img src="+this.bannerNavArrowRight+">"]
  };
  sub_category_responsive:any = {
    0 : {
      items:1
    },
    480 : {
      items:3
    },
    1000 : {
      items:4
    }
  } 
  subCategoryOptions:any = {
    responsive:this.sub_category_responsive,
    dots: true,
    nav:true,
    navigation: false,
    autoplay:true,
    loop:false,
    arrow:true,
    autoplaySpeed:2000,
    navText: ["<img src="+this.bannerNavArrowLeft+">", "<img src="+this.bannerNavArrowRight+">"]
  } 
  public isFullListDisplayed: boolean = false;
  prodcutLoaderBottom:boolean = false;
  fixedFilterFlag:boolean = true;
  priceSliderConfig: any = {
    connect: true,
    start: [this.minValueInput, this.maxValueInput],
    step: 10,
    tooltips: [true,true],
    range: {
      min: this.minValueInput,
      max: this.maxValueInput
    },
    behaviour: 'drag',
    format: {
      to:function(value) {
        return "₹ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }, 
      from:function(value){
        return Math.floor(parseFloat(value.replace(/[^\d.]/g,'')))
      }
    }
    
  };
  priceRange:number[];
  constructor(
    private commonService:CommonService,
    private activatedRouter:ActivatedRoute,
    private router:Router,
    private formBuilder:FormBuilder,    
    private snackBar:MatSnackBar,
    private elements:ElementRef,
    private cartService:CartService,
    private dialog:MatDialog
  ) {
    let user_data:any = JSON.parse(localStorage.getItem('mitashiUser'));
    if(user_data != null) {
      this.userId = user_data[0]['user_id'];
    }
  }

  ngOnInit() {
    this.paramsSubscription = this.activatedRouter.params.subscribe(params=>{
      this.category_link = params['category_id'];
      this.getHomeSettings(this.category_link);
      //clear filters
      if(localStorage.getItem("category_link") != "null"){
        if(this.category_link != localStorage.getItem("category_link")){
          localStorage.setItem("category_link",this.category_link);
          this.pageOffset = 1; 
          localStorage.removeItem("priceFilter");
          localStorage.removeItem("categoryFilter");
          this.minValue = Number(0);
          this.maxValue = Number(0);
        }
      }
      this.subcategory_link = "";
      if(params['subcategory_id'] != ""){
        this.subcategory_link = params['subcategory_id'];
      }
      this.clearFilter();
      if((window.innerWidth) <= 992){
        this.fixedFilterFlag = false;
      }else{
        this.fixedFilterFlag = true;
      }
    });
  }

  // left search filter
  filterfunction(){
    this.pageOffset = 1; 
    this.getAllProducts();
  }

  onChangeSlider(sliderEvent:any){
    console.log(sliderEvent);
    // this.minValueInput = sliderEvent[0];
    // this.maxValueInput = sliderEvent[1];
    localStorage.setItem("priceFilter", JSON.stringify(sliderEvent));
    this.getAllProducts();
  }

  pricrRangefilterfunction(filter_type:string){
    this.pageOffset = 1; 
    //remove filter range 
    if(localStorage.getItem("categoryFilter") !== null){
      const filter_type_array = JSON.parse(localStorage.getItem("categoryFilter"));
      for( var i = 0; i < filter_type_array.length; i++){ 
          if ( filter_type_array[i].filter_type == "price_filter") {
            filter_type_array.splice(i, 1); 
          }
      }
      localStorage.setItem("categoryFilter", JSON.stringify(filter_type_array));
    }
    // console.log("After go",localStorage.getItem("categoryFilter"));
    let price_array:any = [];
    if(filter_type == "slider"){
      let priceRange:any =  this.priceSliderValue.join(',');
      price_array.push(priceRange);
      this.minValueInput = Number(this.priceSliderValue[0]);
      this.maxValueInput = Number(this.priceSliderValue[1]);
    }else{
      let priceRange:any  = Number(this.minValueInput)+','+Number(this.maxValueInput);
      price_array.push(priceRange);
      this.minValue = Number(this.minValueInput);
      this.maxValue = Number(this.maxValueInput);
    }
    localStorage.setItem("priceFilter", JSON.stringify(price_array));
    this.getAllProducts();
  }

  //clearfilter
  clearFilter(){
    this.pageOffset = 1; 
    localStorage.removeItem("priceFilter");
    localStorage.removeItem("categoryFilter");
    this.isFullListDisplayed = false;
    this.getFilters();
    this.getAllProducts();
  }

  getHomeSettings(category_link:any){
    this.commonService.getHomeSettings(category_link).subscribe((response:any)=>{
      if(response){
        if(response.status_code == "200"){
          this.home_banner = response.body.banner_images;
        }
      }
    });
  }

  public getAllProducts(callFrom = ""){
    if(callFrom != "scroll"){
      this.productLoading = true;
      this.pageOffset = 1;
    }
    let productSubscription = this.commonService.getAllProducts(this.category_link,this.subcategory_link,this.productSort,this.pageOffset,this.itemsPerPage).subscribe((response:any)=>{
      if(response.body != null){
        if(callFrom != "scroll"){
          this.allProducts =  response.body.products;
          this.totalProducts = response.body.total_products
        }else{
          let existing = this.allProducts; 
          let newValue = response.body.products
          Array.prototype.push.apply(existing,newValue); 
          this.allProducts =  Object.keys(existing).map(key => {
            if(existing[key] instanceof Array){
              return existing[key];
            }
          }); 
        }
        this.totalProducts = response.body.total_products
        if(this.pageOffset == Math.ceil(this.totalProducts/this.itemsPerPage)){
          this.isFullListDisplayed = true;
        }else{
          this.isFullListDisplayed = false;
        }
      }else{
        if(this.pageOffset == 1){
          this.allProducts =  {};
          this.totalProducts = 0;
        }
      }
      setTimeout(() => {  
        this.productLoading = false;
         this.prodcutLoaderBottom = false;
      }, 1000);
      if(callFrom != "scroll"){
        this.allProductDiv.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
      this.categoryFilterChecked = (JSON.parse(localStorage.getItem("categoryFilter")) != null)?JSON.parse(localStorage.getItem("categoryFilter")):[];
      this.paramsSubscription.unsubscribe();
      productSubscription.unsubscribe();
    });
  }

  /* public getFilters(){
    this.commonService.getFilters(this.category_link).subscribe((response:any)=>{
      if(response.status_code == "200"){
        //sub categories
        this.subCategories = response.body.subcategory;
        //category filter
        this.categoryFilter = response.body.category_filters;
        if(this.categoryFilter != undefined && this.categoryFilter != []){
          if(this.categoryFilter.length > 0){
            let filtered_category:any = JSON.parse(localStorage.getItem("categoryFilter"));
            if(localStorage.getItem("categoryFilter") !== null && filtered_category.length > 0 && filtered_category !="undefined"){
              this.categoryFilter = response.body.category_filters.map(function(category_type_array) {
                category_type_array.filter_parameters.map(function(category_parameter_array){
                  if(filtered_category.indexOf(category_parameter_array.category_filter_parameter_id) > -1){
                    category_parameter_array.isChecked = 'checked';
                  }else{
                    category_parameter_array.isChecked = "null";
                  }
                  return category_parameter_array;
                });
                return category_type_array;
              })
            }
          }
        }
        //price filter
        this.minValueInput = Number(response.body.minprice);
        this.minValue =  Number(response.body.minprice);
        this.maxValueInput = Number(response.body.maxPrice);
        this.maxValue = Number(response.body.maxPrice);
        this.priceFilter = response.body.price_filters;
        this.options =  {
          floor: Number(0),
          ceil:Number(response.body.maxPrice),
          translate: (value: Number, label: any): string => {
            switch (label) {
              case LabelType.Low:
                return '<b>Min price:</b> &#x20B9;' + Number(value);
              case LabelType.High:
                return '<b>Max price:</b> &#x20B9;' + Number(value);
              default:
                return '&#x20B9;' + Number(value);
            }
          }
        };

        //  this code is for refresh filter 
        // let filtered_price:any = JSON.parse(localStorage.getItem("priceFilter"));
        // if(localStorage.getItem("priceFilter") !== null && filtered_price.length > 0 && filtered_price!="undefined"){
        //   let filtered_price_array:any = filtered_price[0].split(',');
        //   if(filtered_price_array != [] && filtered_price_array.length > 0){
        //     this.minValueInput = this.minValue = !isNaN(filtered_price_array[0])?filtered_price_array[0]:0;
        //     this.maxValueInput = this.maxValue = !isNaN(filtered_price_array[1])?filtered_price_array[1]:0;
        //   }
        // }
      }
    });
  } */
  
  public getFilters(){
    this.commonService.getFilters(this.category_link).subscribe((response:any)=>{
      if(response.status_code == "200"){
        //sub categories
        this.subCategories = response.body.subcategory;
        //category filter
        this.categoryFilter = response.body.filterData;
        this.priceFilter = response.body.price_filters;
        this.minValueInput = Number(response.body.minprice);
        this.maxValueInput = Number(response.body.maxPrice);
        this.minValue = Number(response.body.minprice);
        this.maxValue = Number(response.body.maxPrice);
        this.priceRange = [ response.body.minprice,response.body.maxPrice];
        
      }
    });
  }

  addEditWishList(product_data:any){
    if(this.userId != ""){
      if(product_data.wishlisted){
        this.commonService.removeFromWishlist(product_data.wishlist_id).subscribe(response=>{
          if(response.status_code == '200'){
            product_data.wishlisted = !product_data.wishlisted;
            product_data.wishlist_id = 0;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }
        });
      }else{
        this.commonService.addToWishList(product_data.product_id).subscribe(response=>{
          if(response.status_code == '200'){
            product_data.wishlisted = !product_data.wishlisted;
            product_data.wishlist_id = response.body.wishlist_id;
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }else{
            this.snackBar.open(response.msg, "Close", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass:['bg-black']
            });
          }
        });
      }
    }else{
      this.snackBar.open("Please Login for Add this Product To Your Wishlist ", "Close", {
        duration: 3000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
      setTimeout(() => {
        this.dialog.open(LoginComponent, {disableClose: false ,
          data: {}
        });
      }, 3000);
    }
  }

  // addTocart(product_id:any){
  //   let cart_subscription = this.cartService.addToCart(product_id).subscribe(response =>{
  //     if(response.status_code == "200"){
  //       if(response.body.action == "add"){
  //         if(localStorage.getItem("cartCount") != null){
  //           let existing_cart_count:any = localStorage.getItem("cartCount");
  //           existing_cart_count = Number(existing_cart_count)+1;
  //           localStorage.setItem("cartCount",existing_cart_count);
  //         }
  //         this.cartService.changeCartCount();
  //         this.snackBar.open(response.msg, "Close", {
  //           // duration: 2000,
  //           verticalPosition: 'top'
  //         });
  //       }else{
  //         this.snackBar.open(response.msg, "Close", {
  //           // duration: 2000,
  //           verticalPosition: 'top'
  //         });
  //       }
  //     }else{
  //       this.snackBar.open(response.msg, "Close", {
  //         // duration: 2000,
  //         verticalPosition: 'top'
  //       });
  //     }
  //     cart_subscription.unsubscribe();
  //   });
  // }

  onScroll() {
    if(this.isFullListDisplayed == false) {
      this.pageOffset++;
      this.getAllProducts("scroll");
      this.prodcutLoaderBottom = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth <= 992){
      this.fixedFilterFlag = false;
    }else{
      this.fixedFilterFlag = true;
    }
  }
}
