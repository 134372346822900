import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-inpress-details',
  templateUrl: './inpress-details.component.html',
  styleUrls: ['./inpress-details.component.css']
})
export class InpressDetailsComponent implements OnInit {
  @ViewChild("NewsDiv") NewsDiv: ElementRef;
  newsLink:any;
  pageOffset:any =1;
  newsDetails:any;
  constructor( 
    private commonService:CommonService,
    private route:Router,
    private activatedRoute:ActivatedRoute
  ) { 
    this.activatedRoute.params.subscribe((params)=>{
      this.newsLink = params['newsLink'];
    })
  }

  ngAfterViewInit(){
    this.NewsDiv.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  ngOnInit() {
    this.commonService.getNewsDetails(this.newsLink,this.pageOffset).subscribe((response)=>{
      if(response.status_code == "200"){
        if(response.body != null){
          this.newsDetails = response.body.news_details;
        }
      }
    });
  }

}
