import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({ name: 'safeHtml' })
export class SafehtmlentitiesPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value: any, args: any[]): any {
      if (!value) return;
      let txt = document.createElement("textarea");
      txt.innerHTML = value;
      return txt.value;
  }
}