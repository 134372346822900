import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {
  assetsUrl:string = environment.assetsUrl;
  baseUrl:string = environment.baseUrl;
  constructor() { }

  ngOnInit() {
  }

}
