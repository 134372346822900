import { Directive,Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { Subscriber, Subscription } from 'rxjs';


@Directive({
  selector: '[compare]',
  providers:[{provide:NG_VALIDATORS,useExisting:MustMatchDirective,multi:true}]
})
export class MustMatchDirective implements Validator{
  @Input('compare') controlNameToCompare:string;
  validate(control: AbstractControl): ValidationErrors | null{
    const controlToCompare = control.root.get(this.controlNameToCompare);
    if(controlToCompare){
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(()=>{
        control.updateValueAndValidity();
        subscription.unsubscribe();   
      })
    }
    return controlToCompare && controlToCompare.value != control.value ? {'compare':true} : null;
  }
}
