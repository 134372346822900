import { Directive, ElementRef , HostListener , Input, AfterViewInit, Output, EventEmitter, Self } from '@angular/core';
import { ProductsComponent } from '../components/products/products.component';
declare var $: any;
@Directive({
  selector: '[icheck]'
})
export class IcheckDirective {
  @Output() callParentMethod = new EventEmitter();
  $: any = $;
  self = this;
  constructor(
    el: ElementRef,
    private products:ProductsComponent
  ) {
    const currentobj = this;
    this.$(el.nativeElement).iCheck({
      checkboxClass: 'iradio_flat-green',
      radioClass: 'iradio_flat-green',
      labelHover: true
    }).on('ifChecked', function(event:any) {
      // localStorage.clear();return;
      let filter_type:any = $(this).attr("filter-type");
      let filter_name = $(this).attr("filter-name");
      let val = $(this).val();
      //create filter type vise array
      let filterTypeArray:any = [] ; 
      if(localStorage.getItem("categoryFilter") !== null){
        const filter_type_array = JSON.parse(localStorage.getItem("categoryFilter"));
        const arrayIndex: number = filter_type_array.findIndex(function(element) { 
          return element.val == val; 
        }); 
        if (arrayIndex === -1) {
          const temp = {
            "filter_type":filter_type,
            "val":val
          }
          filter_type_array.push(temp);
          localStorage.setItem("categoryFilter", JSON.stringify(filter_type_array));
        }   
        // console.log("After add",localStorage.getItem("categoryFilter"));
      }else{
        const temp = {
          "filter_type":filter_type,
          "val":val
        }
        filterTypeArray.push(temp);
        localStorage.setItem("categoryFilter", JSON.stringify(filterTypeArray));
      }
      // console.log("After add",localStorage.getItem("categoryFilter"));
    //  -------------------------------------------------
      // if (localStorage.getItem(filter_name) === null) {
      //   const filter_array = [];
      //   if(filter_array.indexOf(val) === -1){
      //     filter_array.push(val);
      //   }
      //   localStorage.setItem(filter_name, JSON.stringify(filter_array));
      // }else if(localStorage.getItem(filter_name) !== null){
      //   const filter_array = JSON.parse(localStorage.getItem(filter_name));
      //   if(filter_array.indexOf(val) === -1){
      //     filter_array.push(val);
      //   }
      //   localStorage.setItem(filter_name, JSON.stringify(filter_array));
      // }
      currentobj.products.filterfunction();
    }).on('ifUnchecked',function(event) {
      let filter_name = $(this).attr("filter-name");
      let val = $(this).val();
      //remove value from type vise array
      const filter_type_array = JSON.parse(localStorage.getItem("categoryFilter"));
      if(filter_type_array !== null){
        if(filter_type_array.length > 0){
          // console.log(filter_type_array);return;
          const arrayIndex: number = filter_type_array.findIndex(function(element) { 
            return element.val == val; 
          }); 
          if (arrayIndex !== -1) {
            filter_type_array.splice(arrayIndex, 1);
          }   
          localStorage.setItem("categoryFilter", JSON.stringify(filter_type_array));
        }
      }
      console.log("After remove",localStorage.getItem("categoryFilter"));
      //  -------------------------------------------------
      // const filter_array = JSON.parse(localStorage.getItem(filter_name));
      // if(filter_array !== null){
      //   if(filter_array.length > 0){
      //     const index: number = filter_array.indexOf(val);
      //     if (index !== -1) {
      //       filter_array.splice(index, 1);
      //     }   
      //     localStorage.setItem(filter_name, JSON.stringify(filter_array));
      //   }
      // }
      currentobj.products.filterfunction();
    })
  }
}
