import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './routing/routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/common/layout/layout.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductsComponent } from './components/products/products.component';
import { CommonService } from './services/common.service';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxGalleryModule } from 'ngx-gallery';
import { NgxPaginationModule } from 'ngx-pagination';
import { Products } from './utils/products';
import { BreadcrumbsModule } from "ng6-breadcrumbs";
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { MustMatchDirective } from './directives/must-match.directive';
import { MyAccountComponent } from './components/my-account/my-account.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, style, animate, transition } from '@angular/animations';
import { RequiredifDirective } from './directives/required-if.directive';
import { NotEqualDirective } from './directives/not-equal.directive';
import { ModelDialogComponent } from './components/common/model-dialog/model-dialog.component';
import { AddAddressDialogComponent } from './components/my-account/add-address-dialog/add-address-dialog.component';
import { AddressFilterPipe } from './pipes/my-account-filters/address-filter.pipe';
import { MatchEqualHeightDirective } from './directives/match-equal-height.directive';
import { ConfirmDialogComponent } from './components/common/confirm-dialog/confirm-dialog.component';
import { IcheckDirective } from './directives/icheck.directive';
// import { Ng5SliderModule } from 'ng5-slider';
import { LoadingComponent } from './components/common/loading/loading.component';
import { SafehtmlentitiesPipe } from './pipes/safehtmlentities.pipe';
import { SearchedProductsComponent } from './components/products/searched-products/searched-products.component';
import { CookieService } from 'ngx-cookie-service';
import { CartComponent } from './components/cart/cart.component';
import { CartService } from './services/cart.service';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutGuard } from './auth/checkout.guard';
import { RecentlyViewedComponent } from './components/common/recently-viewed/recently-viewed.component';
import { NotfoundComponent } from './components/common/notfound/notfound.component';
import { CheckoutService } from './services/checkout.service';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { ProductCompareService } from './services/product-compare.service';
import { RatingModule } from 'ng-starrating';
import { AddProductReviewComponent } from './components/products/product-review/add-product-review/add-product-review.component';
import { ListProductReviewComponent } from './components/products/product-review/list-product-review/list-product-review.component';
import { ProductCompareComponent } from './components/products/product-compare/product-compare.component';
import { AllRecentlyViewedComponent } from './components/common/recently-viewed/all-recently-viewed/all-recently-viewed.component';
import { RecentlyViewOrderPipe } from './pipes/recently-view-order.pipe';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { InfinitScrollComponent } from './components/infinit-scroll/infinit-scroll.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { InpressComponent } from './components/inpress/inpress.component';
import { InpressDetailsComponent } from './components/inpress/inpress-details/inpress-details.component';
import { ParticlesComponent } from './components/common/particles/particles.component';
import { CareerComponent } from './components/career/career.component';
import { ImageViewerComponent } from './components/common/image-viewer/image-viewer.component';
import { ImageViewerModule } from "ngx-image-viewer";
import { NouisliderModule } from 'ng2-nouislider';
import { RemoveunderscorePipe } from './pipes/removeunderscore.pipe';
import { PdfViewerComponent } from './components/common/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ProductsComponent,
    ProductDetailsComponent,
    LoginComponent,
    MustMatchDirective,
    MyAccountComponent,
    RequiredifDirective,
    NotEqualDirective,
    ModelDialogComponent,
    AddAddressDialogComponent,
    AddressFilterPipe,
    MatchEqualHeightDirective,
    ConfirmDialogComponent,
    IcheckDirective,
    LoadingComponent,
    SafehtmlentitiesPipe,
    SearchedProductsComponent,
    CartComponent,
    CheckoutComponent,
    RecentlyViewedComponent,
    NotfoundComponent,
    ResetPasswordComponent,
    ProductCompareComponent,
    AddProductReviewComponent,
    ListProductReviewComponent,
    AllRecentlyViewedComponent,
    RecentlyViewOrderPipe,
    AboutusComponent,
    PrivacypolicyComponent,
    InfinitScrollComponent,
    InpressComponent,
    InpressDetailsComponent,
    ParticlesComponent,
    CareerComponent,
    ImageViewerComponent,
    RemoveunderscorePipe,
    PdfViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OwlModule,
    NgxPaginationModule,
    BreadcrumbsModule,
    NgxGalleryModule,
    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    RatingModule,
    PinchZoomModule,
    InfiniteScrollModule,
    AutocompleteLibModule,
    ImageViewerModule.forRoot(),
    NouisliderModule,
    PdfViewerModule
  ],
  entryComponents: [
    ModelDialogComponent,
    MyAccountComponent,
    AddAddressDialogComponent,
    ConfirmDialogComponent,
    LoginComponent,
    ImageViewerComponent
  ],
  providers: [CommonService,Products,AuthService,AuthGuard,CookieService,CartService,CheckoutGuard,CheckoutService,ProductCompareService],
  bootstrap: [AppComponent]
})
export class AppModule {

}
