import { Component, OnInit, ViewChildren, ElementRef, HostListener, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LoginComponent } from '../../login/login.component';
import { ProductCompareService } from 'src/app/services/product-compare.service';


export interface User {
  name: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0.5)' })),
      transition(':enter', [
        style({ transform: 'translateY(-50%)' }),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('300ms ease-out', 
        style({ transform: 'translateY(-50%)' }))
      ])
    ])
  ]
})

export class HeaderComponent implements OnInit {
  assetsUrl:any = environment.assetsUrl;
  categories:any;
  paramSubscription:any;
  searchText:any;
  isScrolled:boolean = false;
  cartCount:any;
  searchToggle:boolean = false;
  menuData:any;


  constructor( 
    private commonService:CommonService,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private auth:AuthService,
    private cookies:CookieService,
    private snackBar:MatSnackBar,
    private cartService:CartService,
    private dialog:MatDialog,
    private productCompareService:ProductCompareService
  ) { 
    this.paramSubscription = this.activatedRoute.params.subscribe(params=>{
      // set unique cart session
      if(localStorage.getItem("cart_session") == "" || localStorage.getItem("cart_session") == null || localStorage.getItem("cart_session") == "undefined"){
        let localStorageUniqueData:any = btoa(Date.now()+ Math.floor(Math.random() * 10000)+ "mitashi");
        localStorage.setItem("cart_session",localStorageUniqueData);
      }
      this.searchText = params.key; 
      this.cartDetails();
      this.getMenuData();
    });
  }

  ngOnInit() {
    this.cartService.updateCartValue().subscribe((item) =>{
      this.cartCount = item;
      this.cartDetails();
    });
  }

  onSubmit(keyText:string){
    if(keyText != "" && keyText != undefined){
      if(keyText.replace(/\s/g,"") != ""){
        this.router.navigate(["searched/"+keyText.replace(/(^\s+|\s+$)/g,'')]);
      }else{
        this.snackBar.open("Please Enter some Values.", "Close", {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:['bg-black']
        });
      }
    }else{
      this.snackBar.open("Please Enter some Values.", "Close", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass:['bg-black']
      });
    }
  }
  getCategories(){
    this.commonService.getCategories().subscribe(response=>{
      if(response.status_code == "200"){
        this.categories = response.body.categories; 
      }
    }); 
  }

  getMenuData(){
    this.commonService.getMenuData().subscribe(response=>{
      if(response.status_code == "200"){
        this.menuData = response.body.menuGroupDetails; 
      }
    }); 
  }

  cartDetails(){
    this.cartService.getCartDetails().subscribe(response=>{
      if(response.status_code == "200"){
        localStorage.setItem("cartCount",response.body.cart_products.length);
      }else{
        localStorage.setItem("cartCount","0");
      }
      this.cartCount = localStorage.getItem("cartCount");
      this.paramSubscription.unsubscribe();
    }); 
  }

  loginOrRegister(){
    this.dialog.open(LoginComponent, {disableClose: true ,
      data: {
        addressId:""
      }
    });
  }

  focusOutFunction(){
    // console.log("called");
    // this.searchToggle = false;
  }
}
